import instance from '../_http'

// 获取省
export function getProvinces() {
    return instance.get(`/dxjc/region/provinces`)
}

// 获取市
export function getCitys(params) {
    return instance.request({
        url:`/dxjc/region/citys`,
        method: 'get',
        params
    })
}

// 获取县
export function getDistricts(params) {
    return instance.request({
        url:`/dxjc/region/districts`,
        method: 'get',
        params
    })
}
// 获取街道
export function getStreets(params) {
    return instance.request({
        url:`/dxjc/region/streets`,
        method: 'get',
        params
    })
}