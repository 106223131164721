<template>
	<div id="app">
		<shop-header v-if="show"></shop-header>
		<!-- <div class="container"></div> -->
		<router-view />
		<shop-footer v-if="show"></shop-footer>
	</div>
</template>
<script>
import ShopHeader from '@/layouts/Header/'
import ShopFooter from '@/layouts/Footer'
export default {
	components: {
		ShopHeader,
		ShopFooter,
	},
	data() {
		return {
			// 是否显示头部和底部展示
			show: true
		} 
	},
	watch: {
		$route: {
			handler: function (val, oldVal) {
				console.log(val.name)
				
				if (val.name == 'login') {
					this.show = false
				} else {
					this.$store.dispatch('getCarNum')
					this.show = true
				}
			},
			// 深度观察监听
			deep: true,
			immediate: true // 监听到路由的变化立即执行
		}
	},
}
</script>

<style lang="less">
@import 'assets/theme/index.less';

.container {
	width: @container-width;
	margin: 0 auto;
}
</style>
