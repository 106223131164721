<template>
    <div class="frame">
        <div class="product" v-if="shopGoodsList.length > 0">
            <div class="p_title" v-if="type == 0">
                <div class="p_t_left"><img src="@/assets/images/icon5.png"></div>
                <div class="p_t_right">
                    <div>热门商品</div>
                    <div>HoT CommoditY</div>
                </div>
            </div>
            <div class="p_title" v-if="type == 3">
                <div class="p_t_left"><img src="@/assets/images/icon5.png"></div>
                <div class="p_t_right">
                    <div>建筑类商品</div>
                    <div>Architectural Products</div>
                </div>
            </div>
            <div class="p_title2" v-if="type == 1">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item v-for="(item, index) in sigleCategory" :key="index">{{ item.frontDesc
                        }}</el-breadcrumb-item>
                </el-breadcrumb>
            </div>
            <div class="product-box">
                <div class="pb_top" v-if="type == 1">
                    <div class="pbr_left">
                        <div @click="nav(0)" :class="{ 'on': sortBy == 0 }">综合
                            <img src="@/assets/images/icon8.png" v-if="sortBy == 0 && sortStatus == 1">
                            <img src="@/assets/images/icon9.png" v-if="sortBy == 0 && sortStatus == 0">
                            <img src="@/assets/images/icon10.png" v-if="sortBy != 0">
                        </div>
                        <div @click="nav(1)" :class="{ 'on': sortBy == 1 }">销量
                            <img src="@/assets/images/icon8.png" v-if="sortBy == 1 && sortStatus == 1">
                            <img src="@/assets/images/icon9.png" v-if="sortBy == 1 && sortStatus == 0">
                            <img src="@/assets/images/icon10.png" v-if="sortBy != 1">
                        </div>

                        <div @click="nav(2)" :class="{ 'on': sortBy == 2 }">最新
                            <img src="@/assets/images/icon8.png" v-if="sortBy == 2 && sortStatus == 1">
                            <img src="@/assets/images/icon9.png" v-if="sortBy == 2 && sortStatus == 0">
                            <img src="@/assets/images/icon10.png" v-if="sortBy != 2">
                        </div>

                        <div @click="nav(3)" :class="{ 'on': sortBy == 3 }">价格
                            <img src="@/assets/images/icon8.png" v-if="sortBy == 3 && sortStatus == 1">
                            <img src="@/assets/images/icon9.png" v-if="sortBy == 3 && sortStatus == 0">
                            <img src="@/assets/images/icon10.png" v-if="sortBy != 3">
                        </div>
                    </div>

                    <div class="pbr_right">
                        <div>共 <span>{{ total2 }}</span> 个结果</div>
                        <div style="margin-left: 10px;"><i class="el-icon-arrow-left" @click="changePage2(0)"></i>
                            <span>{{ current }}</span> / {{ pages }}<i class="el-icon-arrow-right"
                                @click="changePage2(1)"></i>
                        </div>
                    </div>
                </div>


                <div class="pb_top" v-if="type == 2">
                    <div class="pbr_left">
                        <div @click="nav(0)" :class="{ 'on': sortBy == 0 }">综合
                            <img src="@/assets/images/icon8.png" v-if="sortBy == 0 && sortStatus == 1">
                            <img src="@/assets/images/icon9.png" v-if="sortBy == 0 && sortStatus == 0">
                            <img src="@/assets/images/icon10.png" v-if="sortBy != 0">
                        </div>
                        <div @click="nav(1)" :class="{ 'on': sortBy == 1 }">销量
                            <img src="@/assets/images/icon8.png" v-if="sortBy == 1 && sortStatus == 1">
                            <img src="@/assets/images/icon9.png" v-if="sortBy == 1 && sortStatus == 0">
                            <img src="@/assets/images/icon10.png" v-if="sortBy != 1">
                        </div>

                        <div @click="nav(2)" :class="{ 'on': sortBy == 2 }">最新
                            <img src="@/assets/images/icon8.png" v-if="sortBy == 2 && sortStatus == 1">
                            <img src="@/assets/images/icon9.png" v-if="sortBy == 2 && sortStatus == 0">
                            <img src="@/assets/images/icon10.png" v-if="sortBy != 2">
                        </div>

                        <div @click="nav(3)" :class="{ 'on': sortBy == 3 }">价格
                            <img src="@/assets/images/icon8.png" v-if="sortBy == 3 && sortStatus == 1">
                            <img src="@/assets/images/icon9.png" v-if="sortBy == 3 && sortStatus == 0">
                            <img src="@/assets/images/icon10.png" v-if="sortBy != 3">
                        </div>
                    </div>

                    <div class="pbr_right">
                        <div>共 <span>{{ total2 }}</span> 个结果</div>
                        <div style="margin-left: 10px;"><i class="el-icon-arrow-left" @click="changePage2(0)"></i>
                            <span>{{
            current }}</span> / {{
            pages }}<i class="el-icon-arrow-right" @click="changePage2(1)"></i>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="list-box">
                        <div class="list" v-if="type == 0 || type == 1">
                            <div class="item" v-for="(item, index) in shopGoodsList" :key="index"
                                @click="toShopDetails(item.linkId == undefined ? item.id : item.linkId)">
                                <div class="item-img">
                                    <el-image style="width: 195px; height: 195px" :src="imgUrl + item.picUrl"
                                        fit="cover" lazy>
                                        <div slot="placeholder" class="image-slot">
                                            加载中<span class="dot">...</span>
                                        </div>
                                        <div slot="error" class="image-slot">
                                            <el-image :src="require('@/assets/images/err.png')"></el-image>
                                        </div>
                                    </el-image>
                                </div>
                                <div class="item-info">
                                    <div class="i_i_title">
                                        <div class="price"><span>￥</span> {{ item.retailPrice }}元</div>
                                        <div class="p_right">已售：{{ item.salesNumber }}</div>
                                    </div>
                                    <div class="p_name">{{ item.goodsName }}</div>
                                    <div class="info_name">
                                        <img src="@/assets/images/icon13.jpg">{{ item.storeName }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="list" v-if="type == 2">
                            <div class="item" v-for="(item, index) in shopGoodsList" :key="index"
                                @click="toShopDetails(item.id)">
                                <div class="item-img">
                                    <el-image style="width: 195px; height: 195px" :src="imgUrl + item.picUrl"
                                        fit="cover" lazy>
                                        <div slot="placeholder" class="image-slot">
                                            加载中<span class="dot">...</span>
                                        </div>
                                        <div slot="error" class="image-slot">
                                            <el-image :src="require('@/assets/images/err.png')"></el-image>
                                        </div>
                                    </el-image>
                                </div>
                                <div class="item-info">
                                    <div class="i_i_title">
                                        <div class="price"><span>￥</span> {{ item.retailPrice }}元</div>
                                        <div class="p_right">已售：{{ item.salesNumber }}</div>
                                    </div>
                                    <div class="p_name">{{ item.goodsName }}</div>
                                    <div class="info_name">
                                        <img src="@/assets/images/icon13.jpg">{{ item.storeName }}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="list" v-if="type == 3">
                            <div class="item" v-for="(item, index) in shopGoodsList" :key="index"
                                @click="toShopDetails(item.linkId == undefined ? item.id : item.linkId)">

                                <div class="item-img">
                                    <el-image style="width: 195px; height: 195px" :src="imgUrl + item.picUrl"
                                        fit="cover" lazy>
                                        <div slot="placeholder" class="image-slot">
                                            加载中<span class="dot">...</span>
                                        </div>
                                        <div slot="error" class="image-slot">
                                            <el-image :src="require('@/assets/images/err.png')"></el-image>
                                        </div>
                                    </el-image>
                                </div>
                                <div class="item-info">
                                    <div class="i_i_title">
                                        <div class="price"><span>￥</span> {{ item.retailPrice }}元</div>
                                        <div class="p_right">已售：{{ item.salesNumber }}</div>
                                    </div>
                                    <div class="p_name">{{ item.goodsName }}</div>
                                    <div class="info_name">
                                        <img src="@/assets/images/icon13.jpg">{{ item.storeName }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="product2">
            <el-empty description="暂无当前商品"></el-empty>
        </div>

        <div class="pagination" v-if="type == 1 || type == 2 && shopGoodsList.length > 0">
            <el-pagination :current-page="page.pageNum" background :page-size="page.pageSize"
                :hide-on-single-page="value" layout="prev, pager, next" :total="total" @current-change="changePage">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import config from '@/config/index.js'
export default {
    props: {
        type: Number,
        pdata: Array | Object,
        shopGoodsList: Array | Object,
    },

    data() {
        return {
            value: false,
            imgUrl: config.IMGURL,
            page: {
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
            search: '',
            sortBy: 0,
            sortStatus: 0,
            sigleCategory: [],
            records: [],
            total2: 0,
            current: 0,
            pages: 0,
            defaultImg2: 'this.src = "' + require('@/assets/images/err.png') + '"', //默认图片图片的地址
        }
    },
    watch: {
        pdata: {
            handler(nel, old) {
                console.log(nel, '123132')
                if (nel) {
                    this.sigleCategory = nel.sigleCategory
                    this.records = nel.records
                    this.total2 = nel.total
                    this.current = nel.current
                    this.pages = nel.pages
                }

            },
            deep: true,
            immediate: true // 监听到路由的变化立即执行
        }
    },
    methods: {
        //商品详情
        toShopDetails(id) {
            console.log('商品详情id555533', id)
            this.$router.push({ path: '/shopdetails', query: { id: id } })
        },

        //切换页码
        changePage(e) {
            this.$parent.changePage(e)
        },

        changePage2(e) {
            if (e == 0 && this.current > 1) {
                this.current--
            } else if (e == 1 && this.current < this.pages) {
                this.current++
            }
            this.$parent.changePage(this.current)
        },

        nav(e) {
            this.sortBy = e
            if (this.sortStatus) {
                this.sortStatus = 0
            } else {
                this.sortStatus = 1
            }
            this.$parent.sortBy = e
            this.$parent.sortStatus = this.sortStatus
            this.$parent.getShopGoodsList()
        }
    }
}
</script>

<style scoped lang="less">
.frame {
    overflow: hidden;

    .image-slot {
        width: 195px;
        height: 195px;

        image {
            width: 100%;
            height: 100%;
        }
    }

    .product {
        width: 1280px;
        margin: 0 auto;
        overflow: hidden;

        .p_title {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-top: 50px;
            margin-bottom: 4px;

            .p_t_left {
                width: 27px;
                height: 72px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .p_t_right {
                display: flex;
                flex-direction: column;
                margin-left: 12px;
                text-align: left;

                div {
                    &:nth-child(1) {
                        font-size: 30px;
                        font-weight: bold;
                    }

                    &:nth-child(2) {
                        font-size: @main-fontsize-4;
                        color: #C0C0C0;
                        line-height: 35px;
                    }
                }
            }
        }

        .p_title2 {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin-top: 20px;
            margin-bottom: 4px;
        }

        .product-box {
            padding: 0px 0 0px;
            overflow: hidden;

            .pb_top {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                background: white;
                padding: 10px 20px;
                box-sizing: border-box;
                margin: 20px 0;

                .pbr_left {
                    width: 70%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;

                    .on {
                        color: #046EFF !important;
                    }

                    div {
                        width: 60px;
                        font-size: 14px;
                        color: #C0C0C0;
                        cursor: pointer;

                        img {
                            width: 8px;
                            height: 11px;
                            vertical-align: middle;
                        }
                    }
                }

                .pbr_right {
                    width: 30%;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-end;
                    font-size: 14px;
                    color: #999999;

                    span {
                        color: #046EFF !important;
                    }
                }
            }

            .list-box {
                width: 100%;

                .list {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: flex-start;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-bottom: 14px;

                    .item {
                        background: white;
                        padding: 25px 15px;
                        box-sizing: border-box;
                        box-shadow: 5px 5px 10px 5px #F9F9F9;
                        margin: 0 10px 20px 10px;
                        flex: 1;
                        width: calc((100% - 20px*5) / 5);

                        min-width: calc((100% - 20px*5) / 5);
                        max-width: calc((100% - 20px*5) / 5);

                        &:nth-child(5n) {
                            /* 去除第3n个的margin-right */
                            margin-right: 0;
                        }

                        .item-img {
                            width: 195px;
                            height: 195px;
                            margin: 0 auto;
                            cursor: pointer;

                            img {
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .item-info {
                            display: flex;
                            flex-direction: column;
                            justify-content: flex-start;
                            width: 195px;

                            .i_i_title {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                justify-content: space-between;
                                margin: 13px 0 0;

                                .price {
                                    color: @main-color-2;
                                    font-size: @main-fontsize-1;
                                    font-weight: bold;
                                    cursor: pointer;

                                    span {
                                        font-size: 12px;
                                        line-height: 35px;
                                        margin-right: -5px;
                                    }
                                }

                                .p_right {
                                    color: @main-color-4;
                                    font-size: @main-fontsize-3;
                                    line-height: 35px;
                                }
                            }

                            .p_name {
                                width: 100%;
                                height: 48px;
                                color: @main-color-4;
                                font-size: @main-fontsize-2;
                                line-height: 24px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                /* 显示多行文本容器 */
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 2;
                                cursor: pointer;
                                margin-bottom: 8px;
                            }

                            .info_name {
                                width: 100%;
                                display: flex;
                                flex-direction: row;
                                align-items: center;

                                img {
                                    width: 15px;
                                    height: auto;
                                    vertical-align: middle;
                                    margin-right: 5px;
                                }

                                color: @main-color-4;
                                font-size:@main-fontsize-3;
                            }
                        }
                    }
                }
            }
        }
    }

    .product2 {
        width: 1280px;
        height: 59.7vh;
        margin: 0 auto;
        overflow: hidden;
        padding-top: 50px;
        box-sizing: border-box;
    }

    .pagination {
        width: 1280px;
        margin: 30px auto 50px;

        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
}
</style>