import instance from '../_http'

//获取购物车页面详情
export function getCarList(params) {
    return instance.request({
        url: `/buyer/trade/carts/all`,
        method: 'get',
        params
    })
}
//更新购物车选中状态
export function updateChecked(params) {
    return instance.request({
        url: `/buyer/trade/carts/sku/checked`,
        method: 'post',
        params
    })
}
//更新购物车单个产品数量
export function updateNum(params) {
    return instance.request({
        url: `/buyer/trade/carts/sku/num`,
        method: 'post',
        params
    })
}
//删除购物车产品
export function deleteGoods(skuIds) {
    return instance.request({
        url: `/buyer/trade/carts/sku/remove/${skuIds}`,
        method: 'delete',
        // params
    })
}

//提交订单页面 详情
export function submitOrder(params) {
    return instance.request({
        url: `/dxjc/api/order/create/orderPreview`,
        method: 'post',
        data: params
    })
}

//我的订单列表
export function getOrderList(params) {
    return instance.request({
        url: `/dxjc/api/order/list`,
        method: 'get',
        params
    })
}
//删除订单
export function delOrders(ids) {
    return instance.request({
        url: `/dxjc/api/order/${ids}`,
        method: 'delete',
    })
}
//确认收货
export function confirmReceipt(params) {
    return instance.request({
        url: `/dxjc/api/order/confirmReceipt`,
        method: 'get',
        params
    })
}
//获取订单详情
export function getOrderDetails(id) {
    return instance.request({
        url: `/dxjc/api/order/${id}`,
        method: 'get',
    })
}
//默认地址
export function getDefaultAddress(params) {
    return instance.request({
        url: `/dxjc/shopAddressApi/getShopAddressList`,
        method: 'get',
        params
    })
}
//收货地址列表
export function getAddressList(params) {
    return instance.request({
        url: `/dxjc/shopAddressApi/list`,
        method: 'get',
        params
    })
}
// 提交订单（创建订单）
export function createdOrder(params) {
    return instance.request({
        url: `/dxjc/api/order/create/order`,
        method: 'post',
        data: params
    })
}
// 提交退货
export function addReturnGoods(params) {
    return instance.request({
        url: `/dxjc/api/return/addReturnGoods`,
        method: 'post',
        data: params
    })
}

// 退货详情
export function getRefund(params) {
    return instance.request({
        url: `/dxjc/api/return/${params}`,
        method: 'get',
    })
}
//退货发货
export function addReturnShipment(params) {
    return instance.request({
        url: `/dxjc/api/return/addReturnShipment`,
        method: 'post',
        data: params
    })
}


