<template>
	<div class="body">
		<GoodsInfo :goodsInfo="orderInfo"></GoodsInfo>
		<!-- <GoodsStatus :goodsStatus="orderInfo"></GoodsStatus> -->
		<div class="goodsStatus-container">
			<el-steps :active="active" align-center>
				<el-step :title="statusMsgz.orderStatusMsg" icon="none" :description="active >= 1 ? orderInfo.orderChangeLogList[0].createdTime : null">
					<template #icon>
						<img v-if="active >= 1" src="@/assets/images/icon/fahuoActive.png" alt="" />
						<img v-else src="@/assets/images/icon/fahuo.png" alt="" />
					</template>
				</el-step>
				<el-step :title="statusMsgo.orderStatusMsg" icon="none" :description="active >= 2 ? orderInfo.orderChangeLogList[1].createdTime : null">
					<template #icon>
						<img v-if="active >= 2" src="@/assets/images/icon/daishouhuoActive.png" alt="" />
						<img v-else src="@/assets/images/icon/daishouhuo.png" alt="" />
					</template>
				</el-step>
				<el-step :title="statusMsgt.orderStatusMsg" icon="none" :description="active >= 3 ? orderInfo.orderChangeLogList[2].createdTime : null">
					<template #icon>
						<img v-if="active >= 3" src="@/assets/images/icon/yishouhuoActive.png" alt="" />
						<img v-else src="@/assets/images/icon/yishouhuo.png" alt="" />
					</template>
				</el-step>
			</el-steps>
		</div>
		<OrderInfo :orderInfo="orderInfo"></OrderInfo>
	</div>
</template>

<script>
import GoodsInfo from './components/goodsInfo'
import GoodsStatus from './components/goodsStatus'
import OrderInfo from './components/orderInfo'
import { getOrderDetails } from '@/api/common/goods'
// import config from '@/config/index.js'
export default {
	components: {
		GoodsInfo,
		GoodsStatus,
		OrderInfo,
	},
	data() {
		return {
			// imgUrl: config.IMGURL,
			orderInfo: {},
			active: 0,
			statusMsgz: {}, //物流状态描述
			statusMsgo: {},
			statusMsgt: {},
		}
	},
	mounted() {
		console.log(this.$route.query.id)
		this.getOrderDetails()
	},
	methods: {
		getOrderDetails() {
			let id = this.$route.query.id

			getOrderDetails(id).then((res) => {
				if (res.code === 200) {
					this.orderInfo = res.data
					this.statusMsgz = res.data.orderChangeLogList[0]
					this.statusMsgo = res.data.orderChangeLogList[1]
					this.statusMsgt = res.data.orderChangeLogList[2]
				}
				this.getActive()
			})
		},
		getActive() {
			// this.orderInfo.orderChangeLogList.forEach((item) => {
			// 	if (item.isPassStatus) {
			// 		this.active = item.orderStatus
			// 		console.log('订单详情2222233', this.active)
			// 	}
			// })
			this.active = this.orderInfo.orderStatus
			console.log('订单详情2222233', this.orderInfo.orderStatus)
		},
	},
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>