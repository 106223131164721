<template>
    <div>
        <div class="container">
            <img :src="imgUrl" class="marignTop22" />
            <div class="name">{{ name }}</div>
            <div class="details marignTop22">{{ store.storeIntroduce }}</div>
            <div class="flex">
                <div class="button flexcenter" @click="shops(store.id)">进入店铺</div>
                <!-- <div class="button flexcenter marignleft20">关注店铺</div> -->
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'Merchant',
    props: {
        name: String,
        details: String,
        button: Boolean,
        imgUrl: String,
        store: Object
    },

    methods: {
        shops(e) {
            console.log(e)
            this.$router.push({
                query: { id: e },
                path: '/shops'
            })
        }
    }
}
</script>
<style lang="less" scoped>
@import '../index.less';

.container {
    width: 271px;
    height: 299px;
    border: 1px solid @main-color-3;
    background: linear-gradient(180deg, #F5FAFF 0%, #FDFEFF 100%);
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 62px;
        height: 62px;
    }

    .name {
        font-size: @main-fontsize-1;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #443A2E;
        margin-top: 14px;
    }

    .details {
        font-size: @main-fontsize-2;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
        padding: 0 20px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        cursor: pointer;
    }

    .button {
        margin-top: 33px;
        width: 107px;
        height: 44px;
        // border: 1px solid #999999;
        border-radius: 2px;
        opacity: 0.9;
        background-color: @main-color-3;
        cursor: pointer;
    }
}
</style>