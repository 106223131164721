<template>
    <div class="frame">
        <div class="f_content">
            <div class="r_left">
                <img src="@/assets/images/logo3.jpg">
            </div>
            <div class="r_right">
                <div class="r_r_cr_title">欢迎登录</div>
                <div class="r_r_cr_content" v-loading="loading">
                    <el-form class="from" :model="form" ref="form" inline>
                        <el-input v-model="form.username" maxlength="11" minlength="11" placeholder="请输入账号" :border="false"
                            class="input" />
                        <el-input v-model="form.password" show-password placeholder="请输入密码" :border="false" class="input" />
                        <div class="f_row">
                            <el-input v-model="form.code" placeholder="请输入验证码" :border="false" class="input2" />
                            <img :src="cimg" @click="getcode">
                        </div>
                        <div class="f_row2" >
                            <el-checkbox v-model="checked">记住密码</el-checkbox>
                        </div>
                    </el-form>
                </div>
                <el-button class="button" :long="true" @click="submitForm('form')">立即登录</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { getCode } from '@/api/user'
export default {
    name: "login",
    data() {
        return {
            form: {
                username: '',
                password: '',
                uuid: '',
                code: '',
            },
            loading: false,
            cimg: '',
            checked: true
        }
    },

    mounted() {
        if (this.$storage.getItem('user')) {
            this.form.username = this.$storage.getItem('user').username
            this.form.password = this.$storage.getItem('user').password
        }
        this.getcode()
        
    },

    methods: {
        //登录
        async submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/, { form } = this;
                    if (!form.username) {
                        this.$message({
                            message: '请输入账号~',
                            type: 'error'
                        });
                        return;
                    } else if (!form.password) {
                        this.$message({
                            message: '请输入密码~',
                            type: 'error'
                        });
                        return;
                    }else if (!form.code) {
                        this.$message({
                            message: '请输入验证码~',
                            type: 'error'
                        });
                        return;
                    }
                    this.loading = true
                    this.$store.dispatch('getUserInfo', this.form).then(res => {
                        this.loading = false
                        this.$message({
                            message: '登录成功~',
                            type: 'success'
                        });
                        setTimeout(() => {
                            this.$router.replace('/')
                        }, 1500)
                    }).catch(err => {
                        this.loading = false
                        this.$message({
                            message: '登录失败~',
                            type: 'error'
                        });
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },

        //获取验证码
        getcode() {
            getCode().then(res => {
                console.log(res,111)
                this.cimg ='data:image/*;base64,'+ res.img
                this.form.uuid = res.uuid
            })
        }
    }
}
</script>
<style scoped lang="less">
.frame {
    width: 100%;
    min-height: 100vh;
    background: url('@/assets/images/bg2.jpg') no-repeat;
    background-size: 100% 100%;
    display: flex;
    flex-direction: row;
    align-content: center;
    justify-content: space-between;

    /deep/.el-input__inner {
        border: none !important;
    }

    .f_content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin: 200px auto;
        width: 1300px;
        height: auto;

        .r_left {
            width: 418px;
            height: 119px;
            margin-left: -120px;
            margin-top: -120px;
            display: block;

            img {
                width: 100%;
                height: 119px;
            }
        }

        .r_right {
            width: 400px;
            height: 450px;
            background: white;
            border-radius: 10px;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            padding: 50px 40px 0px;
            box-sizing: border-box;
            margin-top: 65px;

            .r_r_cr_title {
                width: 92px;
                color: #0084FE;
                font-size: 21.48px;
                line-height: 50px;
                border-bottom: 4px solid #0084FE;
            }

            .r_r_cr_content {
                width: 319px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: space-around;
                margin-top: 30px;

                .from {
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    align-content: space-around;
                    flex-wrap: wrap !important;

                    .input {
                        width: 100%;
                        line-height: 36px;
                        border-bottom: 1px solid #DFE0E1;
                        margin-bottom: 10px;

                    }

                    .f_row {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;

                        .input2 {
                            width: 50%;
                            height: 100%;
                            line-height: 40px;
                            border-bottom: 1px solid #DFE0E1;
                        }

                        img {
                            width: 100px;
                            height: 40px;
                            margin-left: 10px;
                            display: block;
                        }
                    }
                    .f_row2 {
                        width: 100%;
                        height: auto;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 10px;
                    }
                }
            }

            .button {
                background: #009DFF;
                height: 45px;
                color: white;
                font-size: 14px;
                border: none;
                margin-top: 30px;
                border-radius: 8px;
            }

            .b_title {
                width: 100%;
                margin: 39px auto;
                text-align: center;
                font-size: 14px;
                line-height: 22px;
                color: #999999;
            }
        }
    }
}
</style>  