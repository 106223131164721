<template>
  <div class="footer">
    <div class="footer-link">
      <a href="http://www.mof.gov.cn/index.htm " target="_blank">中华人民共和国财政部网站</a><span>|</span>
      <a href="http://www.linyi.gov.cn/" target="_blank">临沂市人民政府网站</a><span>|</span>
      <a href="http://czt.shandong.gov.cn/" target="_blank">山东省财政厅</a><span>|</span>
      <a href="http://yjsgk.linyi.cn:5000/" target="_blank">临沂市预算公开平台</a><span>|</span>
      <a href="http://czj.linyi.gov.cn/ckxxw.htm" target="_blank">临沂财会信息网</a><span>|</span>
      <a href="https://yaoyao.cebbank.com/" target="_blank">山东省非税统缴平台</a><span>|</span>
      <a href="http://www.hedong.gov.cn/" target="_blank">河东区人民政府</a><span>|</span>
      <a href="http://ggzyjyzx.shandong.gov.cn/" target="_blank">齐鲁云彩临沂城发优文商贸有限公司 【电商】</a>
    </div>
    <div class="footer-link">
      <!-- <a href="" target="_blank">关于我们</a><span>|</span>
      <a href="" target="_blank">联系我们</a><span>|</span>
      <a href="" target="_blank">法律声明</a><span>|</span>
      <a href="" target="_blank">隐私政策</a><span>|</span> -->
      <a href="" target="_blank">鲁ICP备2023037039号</a>
      <!-- <a href="" target="_blank">鲁ICP备xxxxxx号</a> -->
    </div>
    <!-- <div class="footer-link">
      <a href="" target="_blank">© 2016-现在 政采云有限公司 版权所有 增值电信业务经营许可证：鲁B2-20160869
        增值电信业务许可证</a>
    </div> -->
  </div>
</template>
<script>
export default {
  name: 'nav-footer'
}
</script>
<style lang="less" scoped>
.footer {
  height: 197px;
  background-color: #47474D;
  color: #999999;
  font-size: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .footer-link {
    margin: 0 auto;

    a {
      color: #999999;
      display: inline-block;
      font-size: 18px;
    }

    span {
      margin: 0 10px;
    }

    margin-bottom:13px;
  }

  .copyright {
    .domain {
      color: #FF6600;
    }
  }
}
</style>