<template>
	<div>
		<div class="address-container">
			<div class="header-btn">
				<h3>地址维护</h3>
				<button class="info" @click="addAddress">新增地址</button>
			</div>

			<table class="address">
				<thead class="address-head">
					<tr class="address-tr">
						<th>收货人</th>
						<th>所在地址</th>
						<th>手机号</th>
						<th>操作</th>
					</tr>
				</thead>
				<tbody class="address-body">
					<tr class="address-body-tr" v-for="(item,index) in addressList" :key="index">
						<td>{{item.name}}</td>
						<td>{{item.provinceName+item.cityName+item.countyName}}
						{{item.districtName==null?'':item.districtName}}
						{{item.address}}
						</td>
						<td>{{item.mobile}}</td>
						<td class="address-body-tr-action">
							<div>
								<span @click="updateAddress(item.id)">修改</span>
								<span @click="deleteAddress(item.id)">删除</span>
								<span @click="setDefaultAddress(item.id)" :class="[item.isDefault == 1 ?'default':'']">{{item.isDefault == 1 ? '默认地址':'设为默认'}}</span>
							</div>
						</td>
					</tr>
					<!-- <tr class="address-body-tr">
						<td class="td-receiver">文本信息文本</td>
						<td class="td-address">文本信息文本文本信息文本文本信息文本息文本</td>
						<td class="td-phone">17753908176</td>
						<td class="address-body-tr-action">
							<div><span>修改</span><span>删除</span><span>设为默认</span></div>
						</td>
					</tr> -->
				</tbody>
			</table>
		</div>
		<div v-if="addressList.length == 0">
			<el-empty description="暂无地址"></el-empty>
		</div>
		<div class="page-box"  v-if="addressList.length !=0">
			<el-pagination background  layout="prev, pager, next"	:total="total"   @size-change="addressFenye(e)" @current-change="addressFenye"
				@prev-click="addressFenye" @next-click="addressFenye">
			</el-pagination>
		</div>
	</div>
</template>
<script>
export default {
	props:{
		addressList:{
			type:Array,
			default:[]
		},
		total:{
			type:Number,
			default:100
		}
	},
	watch: {
		addressList(newVal, oldVal) {
			// console.log(newVal, oldVal)
		}
	},
	data() {
		return {

		}
	},
	methods: {
		updateAddress(id) {
			this.$emit('updateAddress',id)
		},
		addAddress(){
			this.$emit('updateAddress','add')
		},
		deleteAddress(id){
			this.$emit('deleteAddress',id)
		},
		setDefaultAddress(id){
			// console.log(id,'sda')
			this.$emit('setDefaultAddress',id)
		},
		addressFenye(e){
			this.$emit('addressFenye',e)
		}
	}
}
</script>
<style lang="less" scoped>
@import 'index.less';
.header-btn{
	justify-content: space-between;
	display: flex;
	padding-right: 26px;
	button{
		font-size: @main-fontsize-2;
		width: 100px;
		height: 32px;
		background: #A9ABAC;
		cursor: pointer;
		opacity: 0.6;
		border-radius: 16px;
	}
}

.info{
    color: #ffffff;
    border: 1px solid @main-color-3;
    margin-bottom: 25px;
}
</style>