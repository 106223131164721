<template>
	<div>
		<table class="order-container" v-for="(item, index) in goodsList" :key="index">
			<thead>
				<tr>
					<th class="th-one" colspan="3">{{ item.createdTime }}</th>
					<th class="th-orderId">订单编号：{{ item.orderSn }}</th>
					<th class="th-one"></th>
					<th class="th-two"></th>
					<th class="th-three"></th>
					<th class="th-four"></th>
					<th class="th-five">
						<img src="@/assets/images/icon/delete.png" alt="" @click="deleteOrder(item.id)"
							v-if="item.orderStatus == 3">
					</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td class="td-one" colspan="3">
						<img :src="imgUrl + item.subPicUrl" alt="" width="150" height="120">
					</td>
					<td class="td-description">
						<div class="goods-detail">
							<div class="goodsName">{{ item.subGoodsName }}</div>
							<div class="specifications">产品规格：{{ item.subSpecifications }}</div>
						</div>
					</td>
					<td class="td-number">
						<div class="number">x{{ item.subNumber }}</div>
					</td>
					<td class="td-two">{{ item.consignee }}</td>
					<td class="td-three">￥{{ item.actualPrice }}</td>

					<td class="td-four">
						<div class="goodsState" v-if="item.orderStatus === 1">待发货</div>
						<div class="goodsState" v-if="item.orderStatus === 2">待收货</div>
						<div class="goodsState" v-if="item.orderStatus === 3">交易完成</div>
						<div class="goodsState" v-if="item.orderStatus == 3 && item.buyEvaluateStatus === 1">待评价</div>
						<div class="goodsState" v-if="item.orderStatus == 3 && item.buyEvaluateStatus === 2">已评价</div>
						<div class="goodsState" v-if="item.orderStatus == 3 && item.buyEvaluateStatus === 3">已回复</div>
						<div class="goodsState" v-if="item.orderStatus == 3 &&item.returnGoodsStatus === 1">退货待审核</div>
						<div class="goodsState" v-if="item.orderStatus == 3 &&item.returnGoodsStatus === 2">退货已拒绝</div>
						<div class="goodsState" v-if="item.orderStatus == 3 &&item.returnGoodsStatus === 3">退货待发货</div>
						<div class="goodsState" v-if="item.orderStatus == 3 &&item.returnGoodsStatus === 4">退货待收货</div>
						<div class="goodsState" v-if="item.orderStatus == 3 &&item.returnGoodsStatus === 5">退货完成</div>
					</td>

					<td class="td-five">
						<div class="button" type="primary" @click="handleClick2(item.id)"
							v-if="item.orderStatus == 3 && (item.returnGoodsStatus == null || item.returnGoodsStatus == 2)">
							申请退货
						</div>

						<div class="button" type="primary" @click="handleClick3(item.id)" v-if="item.returnGoodsStatus > 1">
							退货详情
						</div>
						<div class="button" type="primary" @click="handleClick3(item.id)"
							v-if="item.returnGoodsStatus == 3">
							退货发货
						</div>
						<div class="button" type="primary" @click="handleClick(item.id)" v-if="item.orderStatus == 2">确认收货
						</div>
						<div class="button" type="primary" @click="handlePingjia(item.subId, item)"
							v-if="item.orderStatus == 3 && item.buyEvaluateStatus == 1 && !item.returnGoodsStatus">立即评价
						</div>
						<div class="button" type="primary" @click="orderDetails(item.id)">订单详情
						</div>
					</td>
				</tr>
			</tbody>
		</table>
		<!-- <OrderItem></OrderItem> -->
	</div>
</template>
<script>
// import OrderItem from '../orderitem'
import config from '@/config/index.js'
export default {
	// components: {
	// 	OrderItem,
	// },
	props: {
		goodsList: {
			type: Array,
			default: []
		}
	},
	data() {
		return {
			imgUrl: config.IMGURL,
			// goodsList: [], //订单列表
		}
	},
	methods: {
		//订单详情
		orderDetails(id) {
			// console.log('订单id',id)
			this.$emit('orderDetails', id)
		},
		//删除订单
		deleteOrder(id) {
			this.$emit('deleteOrder', id)
		},
		//确认收货
		handleClick(id) {
			// console.log('hhhhhhhh',typeof(id))
			this.$emit('confirmReceipt', id)
		},

		//退货
		handleClick2(id) {
			// console.log('hhhhhhhh',typeof(id))
			this.$emit('confirmReceipt2', id)
		},

		//退货
		handleClick3(id) {
			// console.log('hhhhhhhh',typeof(id))
			this.$emit('confirmReceipt3', id)
		},
		handlePingjia(subId, item) {
			const data = {
				subId: subId,
				item: item
			}
			this.$emit('pingjia', data)
		}
	}
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>