<template>
	<div class="body">
		<!-- 收货地址 -->
		<h1>收货人信息</h1>
		<div class="address-info">
			<!-- 默认地址、新增地址 -->
			<div class="address-info-flex">
				<div class="address-info-flex-default">
					<div v-if="!isdefaultAddressNull" class="address-info-flex-default-name" :class="{ active: isActive, inactiveName: !isActive }" @click="checkedAddress">{{ defaultAddress.name }}</div>
					<div v-else class="address-info-flex-default-name" :class="{ inactiveNullName: !isActive }">未选地址</div>
					<div v-if="!isdefaultAddressNull" class="address-info-flex-default-address">
						<span>{{ defaultAddress.name }}</span>
						<span class="details">{{ defaultAddress.alladdressName }}</span>
						<span class="mobile">{{ defaultAddress.mobile }}</span>
					</div>
					<div class="address-info-flex-default-def" v-if="defaultAddress.isDefault === 1">默认地址</div>
				</div>
				<div class="address-info-flex-add" @click="openDialog">新增收货地址</div>
			</div>
			<!-- 其他收货地址 -->
			<div class="address-info-choice">
				<el-dropdown v-if="!isOtherAddressNull" @command="handleCommand" placement="bottom-start">
					<span class="el-dropdown-link"> 选择其他地址<i class="el-icon-arrow-down el-icon--right"></i> </span>
					<el-dropdown-menu slot="dropdown">
						<!-- <div> -->
						<el-dropdown-item v-for="(item, index) in addressList" :key="index" :command="index">{{ item.name }}&nbsp;{{ item.alladdressName }}&nbsp;{{ defaultAddress.mobile }}</el-dropdown-item>
						<!-- </div> -->
					</el-dropdown-menu>
				</el-dropdown>
			</div>
			<!-- 新增地址弹框 -->
			<UpdateAddress :dialogVisible="updateAddressShow" @closeDialog="closeDialog" @submitDialog="submitDialog" :title="title"> </UpdateAddress>
		</div>
		<!-- 订单预览详情 -->
		<div class="info">
			<h1>
				全部商品<span>{{ carDates.num }}</span
				>件
			</h1>
			<div class="cart-container" ref="cartContainer">
				<!-- @scroll="handleScroll" -->
				<table>
					<thead>
						<tr>
							<!-- <th class="th-1">
								<input type="checkbox" v-model="carDates.checked" @change="selectAllItems" />
							</th> -->
							<th class="th-2"><span>全部</span></th>
							<th class="th-3">
								<span class="goods-info">商品信息</span>
								<span class="goods-guige">规格</span>
							</th>
							<th class="th-4">单价（元）</th>
							<th class="th-5">数量</th>
							<th class="th-6">小计</th>
							<!-- <th class="th-7">操作</th> -->
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in goodsList" :key="index">
							<!-- <td class="td-1">
								<input type="checkbox" v-model="item.checked" @change="selectItem(item)" />
							</td> -->
							<td class="td-2">
								<img :src="imgUrl + item.url" alt="" width="87" height="87" />
							</td>
							<td class="td-3">{{ item.goodsName }}{{ item.simpleSpecs }}</td>
							<td class="td-4">￥{{ item.purchasePrice }}</td>
							<td class="td-5"><el-input-number v-model="item.num" :disabled="true" @change="handleChange(item)" :min="1" :max="100" size="mini"></el-input-number>{{ item.unitName }}</td>
							<td class="td-6">￥{{ item.subTotal }}</td>
							<!-- <td class="td-7">
								<button @click="deleteItem(item)"><i class="el-icon-delete"></i>删除</button>
							</td> -->
						</tr>
					</tbody>
				</table>
			</div>
			<div class="gap"></div>
			<div class="foot">
				<!-- <div class="foot-left">
					<div class="all-check">
						<input type="checkbox" v-model="carDates.checked" @change="selectAllItems" />
						<span>全选</span>
					</div>
					<button @click="deleteSelectedItems">删除选中</button>
					<button @click="deleteSelectedStatus">快速清除</button>
				</div> -->
				<div class="foot-right">
					<!-- <div class="check-num">
						已选<span class="num">{{ carDates.checkedNum }}</span
						>件商品
					</div> -->
					<div class="total">
						应付总额：<span class="symbol"
							>￥<span class="price">{{ carDates.actualPrice }}</span></span
						>
					</div>
					<div v-if="!isdefaultAddressNull" class="address">
						<span>寄送至：{{ defaultAddress.alladdressName }}</span>
						<span class="receiver">收货人：{{ defaultAddress.name }}&nbsp;{{ defaultAddress.mobile }}</span>
					</div>
				</div>
			</div>
			<div class="account" @click="goAccount">
				<button>提交订单</button>
			</div>
		</div>
	</div>
</template>
  
<script>
import { getCarList, updateChecked, updateNum, deleteGoods, submitOrder, getDefaultAddress, getAddressList, createdOrder } from '@/api/common/goods.js'
import UpdateAddress from '../../components/updateAddress.vue'
import config from '@/config/index.js'
export default {
	components: {
		UpdateAddress,
	},
	data() {
		return {
			imgUrl: config.IMGURL,
			num: 1, //商品数量
			carDates: {}, //购物车数据
			goodsList: [], //购物车商品列表
			updateAddressShow: false, //是否打开新增地址弹框
			title: '新增地址',
			isActive: false, //是否激活名字样式
			defaultAddress: {}, //默认收货地址
			addressList: [], //收货地址列表
			pageNum: 1,
			pageSize: 10000,
			isdefaultAddressNull: false,
			isOtherAddressNull:false
		}
	},
	created() {
		console.log(this.$route, 'routerrouter')
		// console.log(JSON.parse(this.$route.query.orderArr))
	},
	mounted() {
		this.getDefaultAddress()
		this.getAddressList()
		this.submitOrder()
	},
	methods: {
		//获取购物车详情
		submitOrder() {
			try {
				const shopOerderAddDTOList = JSON.parse(this.$route.query.shopOerderAddDTOList)
				let params = {
					cartType: this.$route.query.cartType,
					shopOerderAddDTOList: shopOerderAddDTOList,
				}
				console.log('参数', shopOerderAddDTOList, params)
				submitOrder(params).then((res) => {
					if (res.code === 200) {
						this.carDates = res.data
						this.goodsList = res.data.dataSkuList
						console.log('订单详情', this.carDates, this.goodsList)
					}
				})
			} catch (error) {
				console.error(error)
			}
		},
		// 获取默认地址
		getDefaultAddress() {
			let params = {}
			getDefaultAddress(params).then((res) => {
				if (res.code === 200) {
					this.defaultAddress = res.data
					console.log('默认地址', this.defaultAddress)
					if (res.data.isDefault === 1) {
						this.isActive = true
					}
					if (res.data.id === null) {
						this.isdefaultAddressNull = true
					} else {
						this.isdefaultAddressNull = false
					}
				}
			})
		},
		//获取地址列表
		getAddressList() {
			let params = {
				pageNum: this.pageNum,
				pageSize: this.pageSize,
			}
			getAddressList(params).then((res) => {
				if (res.code === 200) {
					this.addressList = res.data.records
					if (res.data.records.length==0) {
						this.isOtherAddressNull = true
					} else {
						this.isOtherAddressNull = false
					}
					// this.defaultAddress = res.data
					console.log('地址列表', res)
				}
			})
		},
		//勾选默认地址
		checkedAddress() {
			this.isActive = !this.isActive
		},
		//选择地址
		handleCommand(command) {
			this.defaultAddress = this.addressList[command]
			this.isdefaultAddressNull = false
			this.isActive = true
		},
		submitDialog() {
			this.getDefaultAddress()
			this.getAddressList()
			this.updateAddressShow = false
		},
		openDialog() {
			this.updateAddressShow = true
		},
		closeDialog() {
			this.updateAddressShow = false
		},
		// 提交订单
		goAccount() {
			if (this.isActive) {
				try {
					const shopOerderAddDTOList = JSON.parse(this.$route.query.shopOerderAddDTOList)
					let params = {
						cartType: this.$route.query.cartType,
						shopOerderAddDTOList: shopOerderAddDTOList,
						addressId: this.defaultAddress.id,
					}
					console.log('参数', shopOerderAddDTOList, params)
					createdOrder(params).then((res) => {
						if (res.code === 200) {
							// this.carDates = res.data
							// this.goodsList = res.data.dataSkuList
							// console.log('提交订单成功')
							this.$message({
								message: '提交成功',
								type: 'success',
							})
							// window.close()
						}
					})
					
					// this.$nextTick(() => {
					// 	console.log('跳转页面')
						this.$router.replace({
							path: '/orderlist',
						})
					// })
				} catch (error) {
					console.error(error)
				}
			} else {
				this.$message({
					message: '请选择收货地址~',
					type: 'warning',
				})
			}
		},
		//商品数量
		handleChange(item) {
			let params = {
				num: item.num,
				skuId: item.skuId,
			}
			updateNum(params).then((res) => {
				console.log('更新数量', res)
				// 刷新购物车数据
				this.submitOrder()
			})
		},
		//更新选中状态
		updateChecked(item) {
			console.log('选中状态', item.skuId, item.checked)
			let params = {
				checked: item.checked,
				skuIds: item.skuId,
			}
			updateChecked(params).then((res) => {
				console.log('更新状态成功')
				// 刷新购物车数据
				this.submitOrder()
			})
		},
		//删除商品更新
		deleteGoods(item) {
			console.log('删除', item.skuId)
			// let skuIds = item.skuId
			let params = {
				skuIds: item.skuId,
			}
			deleteGoods(params).then((res) => {
				// 刷新购物车数据
				this.submitOrder()
			})
		},
		//全选
		async selectAllItems() {
			for (const item of this.goodsList) {
				await new Promise((resolve, reject) => {
					//发送数据请求
					item.checked = this.carDates.checked
					let params = {
						checked: item.checked,
						skuIds: item.skuId,
					}
					updateChecked(params)
						.then(() => {
							// 数据发送成功后，执行resolve()来继续遍历下一项
							resolve()
						})
						.catch((error) => {
							// 数据发送失败时，执行reject()来中止循环
							console.log('全选失败', item)
							reject(error)
						})
				})
			}
			// 遍历完成刷新购物车数据
			this.submitOrder()
		},
		// 商品选中状态
		selectItem(item) {
			if (!item.checked) {
				this.carDates.checked = false
			} else {
				this.carDates.checked = this.goodsList.every((item) => item.checked)
			}
			this.updateChecked(item)
			// 刷新购物车数据
			// this.$nextTick(() => {
			//  this.submitOrder()
			// })
		},

		// 删除单个商品
		deleteItem(item) {
			console.log('商品删除', item, item.skuId)
			this.goodsList = this.goodsList.filter((i) => i.skuId !== item.skuId)
			this.deleteGoods(item)
		},
		// 删除所选商品
		async deleteSelectedItems() {
			let goodsDeleteList = this.goodsList.filter((item) => item.checked) //筛选出已选的商品放进数组
			for (const item of goodsDeleteList) {
				await new Promise((resolve, reject) => {
					console.log('删除', item)
					let params = {
						skuIds: item.skuId,
					}
					deleteGoods(params)
						.then((res) => {})
						.then(() => {
							// 数据发送成功后，执行resolve()来继续遍历下一项
							resolve()
						})
						.catch((error) => {
							// 数据发送失败时，执行reject()来中止循环
							console.log('全选失败', item)
							reject(error)
						})
				})
			}
			// 遍历完成刷新购物车数据
			this.submitOrder()
		},
		// 清除商品选择状态
		async deleteSelectedStatus() {
			for (const item of this.goodsList) {
				await new Promise((resolve, reject) => {
					//发送数据请求
					item.checked = false
					let params = {
						checked: item.checked,
						skuIds: item.skuId,
					}
					updateChecked(params)
						.then(() => {
							// 数据发送成功后，执行resolve()来继续遍历下一项
							resolve()
						})
						.catch((error) => {
							// 数据发送失败时，执行reject()来中止循环
							console.log('全选失败', item)
							reject(error)
						})
				})
			}
			// 遍历完成刷新购物车数据
			this.submitOrder()
		},
	},
}
</script>

<style lang="less" scoped>
@import 'index.less';
</style>