<template>
	<div class="container">
		<div class="top" v-if="topShow">
			<div class="top-info">欢迎来到政采云,<router-link to="/login" @click="click(0)">请登录~</router-link></div>
		</div>
		<div class="header">
			<div class="header-info">
				<img @click="click(1)" class="img" :src="require('@/assets/images/logo.png')" alt="" />
				<div class="right" v-if="isShowSearch">
					<div class="search">
						<img :src="require('@/assets/images/icon/search.png')" alt="" />
						<input type="text" v-model="search" placeholder="请输入搜索商品" />
						<button @click="getSearch()">搜索</button>
					</div>
					<button class="shop-car" @click="click(2)">
						<img :src="require('@/assets/images/icon/shopcar.png')" alt="" />
						购物车({{ $store.state.carNum }})
					</button>
				</div>
				<div v-if="showLoginOut">
					<button class="header-btn-info" @click="loginOut">退出登录</button>
				</div>
			</div>
		</div>
		
	</div>
</template>
<script>
import { cartGoodsNum } from '@/api/shop/shop.js'
import {logout} from '@/api/user/index.js'
import storage from '@/utils/storage'
export default {
	name: 'shopheader',
	data() {
		return {
			isShowSearch: true,
			topShow: true,
			carNum: this.$store.state.carNum,
			search: '',
			route: '',
			showLoginOut:false
		}
	},
	watch: {
		$route: {
			handler(nel, old) {
				// console.log(nel,'jhjkhj')
				this.cartGoodsNum()
				if(nel.path=='/user'){
					this.showLoginOut = true
				}else{
					this.showLoginOut = false
				}
				if (nel.query.search) {
					this.search = nel.query.search
				}
				// this.isShowSearch = nel.meta.isSearch
				if (this.$storage.getItem('token')) {
					this.topShow = false
				}

			},
			immediate: true,
		},
	},

	methods: {
		//退出登录
		loginOut(){
			this.$confirm('此操作将退出登录, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			}).then(() => {
				logout().then(res=> {
					if(res.code == 200){
						this.$message({
							type: 'success',
							message: res.msg,
						})
						storage.clearItem('userinfo')
						storage.clearItem('token')
						this.$router.replace({
							path: '/login',
						})
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除',
				})
			})
		},
		//获取购物蹙额数量
		cartGoodsNum() {
			if (this.$storage.getItem('token')) {
				cartGoodsNum().then(res => {
					this.carNum = res.data
				})
			}
		},

		click(e) {
			this.search = ''
			switch (e) {
				case 0:
					this.$router.push('/login')
					break;
				case 1:
					this.$router.push('/')
					break;
				case 2:
					this.$router.push('/shopcar')
					break;
			}
		},

		//搜索
		getSearch(e) {
			console.log(this.$route, 'r')
			if (this.search) {
				if (this.$route.path == '/shopList') {
					this.$router.replace({ path: '/shopList', query: { search: this.search, } })
					location.reload()
					this.search = this.$route.query.search
				} else {
					this.$router.push({ path: '/shopList', query: { search: this.search, } })
				}
			}
		}
	}
}
</script>
<style lang="less" scoped>
@import 'index.less';
@import '../../views/user/components/UserInfo/index.less';

img {
	cursor: pointer;
}
.header-btn-info{
	    font-size: 14px;
    width: 100px;
    height: 32px;
    background: #A9ABAC;
    cursor: pointer;
    opacity: 0.6;
    border-radius: 16px;
	color: #fff;
}
</style>