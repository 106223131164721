import instance from '../_http'

// //商品列表
export function shopGoodsList(params) {
    return instance.request({
        url: `/dxjc/api/shopGoods/list`,
        method: 'get',
        params
    })
}

//商品列表
export function listAllHotGoods(params) {
    return instance.request({
        url: `/dxjc/api/fitment/listAllHotGoods`,
        method: 'get',
        params
    })
}
//商品详情
export function shopGoodsDetails(id) {
    return instance.request({
        url: `/dxjc/api/shopGoods/${id}`,
        method: 'get',
    })
}

//商品用户评价
export function goodsEvaluation(params) {
    return instance.request({
        url: `/dxjc/api/shopGoodsAssess/goodsEvaluation`,
        method: 'get',
        params
    })
}

//查询商品规格列表
export function skulist(data) {
    return instance.request({
        url: `/dxjc/api/sku/list`,
        method: 'post',
        data: data
    })
}

//加入购物车
export function addCart(params) {
    return instance.request({
        url: `/buyer/trade/carts`,
        method: 'post',
        params
    })
}

//精选商铺列表
export function getTopStore(params) {
    return instance.request({
        url: `/dxjc/api/fitment/listAllHotStores`,
        method: 'get',
        params
    })
}
// //精选商铺列表
// export function getTopStore(params) {
//     return instance.request({
//         url: `/dxjc/api/store/getTopStore`,
//         method: 'get',
//         params
//     })
// }

//广告位
export function getSwiper(params) {
    return instance.request({
        url: `/dxjc/api/adInfo/listAll`,
        method: 'get',
    })
}
// //广告位
// export function getSwiper(params) {
//     return instance.request({
//         url: `/dxjc/api/ad/list/${params}`,
//         method: 'get',
//     })
// }
//分类
export function treeselect(params) {
    return instance.request({
        url: `/dxjc/api/shopCategory/treeselect`,
        method: 'get',
    })
}

//获取购物车数量
export function cartGoodsNum(params) {
    return instance.request({
        url: `/buyer/trade/carts/cartGoodsNum`,
        method: 'get',
    })
}

//查询商铺商品列表
export function getGoodslist(params) {
    return instance.request({
        url: `/dxjc/api/renovation/getGoodslist`,
        method: 'get',
        params
    })
}

//查询建筑类商品列表
export function getListArchGoods(params) {
    return instance.request({
        url: `/dxjc/api/fitment/getListArchGoods`,
        method: 'get',
        params
    })
}

//查询商铺首页
export function getStoreDetail(params) {
    return instance.request({
        url: `/dxjc/api/renovation/${params}`,
        method: 'get',
    })
}
//查询商铺详情
export function storesDetail(params) {
    return instance.request({
        url: `/dxjc/api/stores/${params}`,
        method: 'get',
    })
}



