<template>
	<div class="goodsStatus-container">
		<el-steps :active="active" align-center>
			<!-- <el-step title="提交订单" icon="none" :description="active >= 1 ? goodsStatus.createdTime : null">
				<template #icon>
					<img v-if="active >= 1" src="@/assets/images/icon/tijiaoActive.png" alt="" />
					<img v-else src="@/assets/images/icon/tijiao.png" alt="" />
				</template>
			</el-step> -->
			<el-step :title="goodsStatus.orderChangeLogList[0].orderStatusMsg" icon="none" :description="active >= 1 ? goodsStatus.orderChangeLogList[0].createdTime : null">
				<template #icon>
					<img v-if="active >= 1" src="@/assets/images/icon/fahuoActive.png" alt="" />
					<img v-else src="@/assets/images/icon/fahuo.png" alt="" />
				</template>
			</el-step>
			<el-step :title="goodsStatus.orderChangeLogList[1].orderStatusMsg" icon="none" :description="active >= 2 ? goodsStatus.orderChangeLogList[1].createdTime : null">
				<template #icon>
					<img v-if="active >= 2" src="@/assets/images/icon/daishouhuoActive.png" alt="" />
					<img v-else src="@/assets/images/icon/daishouhuo.png" alt="" />
				</template>
			</el-step>
			<el-step :title="goodsStatus.orderChangeLogList[2].orderStatusMsg" icon="none" :description="active >= 3 ? goodsStatus.orderChangeLogList[2].createdTime : null">
				<template #icon>
					<img v-if="active >= 3" src="@/assets/images/icon/yishouhuoActive.png" alt="" />
					<img v-else src="@/assets/images/icon/yishouhuo.png" alt="" />
				</template>
			</el-step>
		</el-steps>
	</div>
</template>

<script>
export default {
	props: {
		goodsStatus: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			active: 2,
		}
	},
	mounted() {
		// this.$nextTick(() => {
		// 	console.log('hduhfeuhfhfhr', this.goodsStatus)
		// })
	},
	methods: {
		// getActive() {
		// 	console.log('hduhfeuhfhfhr', this.goodsStatus.orderChangeLogList)
		// },
	},
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>