import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/home.vue'
import user from '../views/user/'
import shopcar from '../views/shopcar'
import account from '../views/account'
import orderlist from '../views/orderlist'
import orderEvaluate from '../views/orderlist/orderEvaluate'
import allshop from '../views/user/allshop'
import orderdetails from '../views/orderdetails'
import shopdetails from '../views/shopDetails'
import login from '../views/login.vue'
import shopList from '../views/shopList'
Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		component: HomeView,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/user',
		name: 'user',
		component: user,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/allshop',
		name: 'allshop',
		component: allshop,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/shopcar',
		name: 'shopcar',
		component: shopcar,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/account',
		name: 'account',
		component: account,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/orderlist',
		name: 'orderlist',
		component: orderlist,
		meta: {
			isSearch: false,
		},
	}, {
		path: '/orderEvaluate',
		name: 'orderEvaluate',
		component: orderEvaluate,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/orderdetails',
		name: 'orderdetails',
		component: orderdetails,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/shopdetails',
		name: 'shopdetails',
		component: shopdetails,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/login',
		name: 'login',
		component: login,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/shopList',
		name: 'shopList',
		component: shopList,
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/redundFrom',
		name: 'redundFrom',
		component: () => import('../views/refund/redundFrom'),
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/refundIndex',
		name: 'refundIndex',
		component: () => import('../views/refund/refundIndex'),
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/register',
		name: 'register',
		component: () => import('../views/register'),
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/merchant',
		name: 'merchant',
		component: () => import('../views/user/merchant'),
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/shopIndex',
		name: 'shopIndex',
		component: () => import('../views/shop/index'),
		meta: {
			isSearch: false,
		},
	},
	{
		path: '/shops',
		name: 'shops',
		component: () => import('../views/shop/shops'),
		meta: {
			isSearch: false,
		},
	}


	// {
	//   path: '/about',
	//   name: 'about',
	//   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
	// }
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
})

export default router
