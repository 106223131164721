<template>
    <div class="container">
        <GoodsInfo :goodsInfo="orderInfo"></GoodsInfo>
        <div>
            <el-input type="textarea" placeholder="请输入内容" v-model="pingjiaValue" maxlength="500" rows="8" show-word-limit>
            </el-input>

            <div class="button_bottom">
                <el-button :disabled="isDisabled" type="danger" @click="submitDialog">确认提交</el-button>
            </div>
        </div>
    </div>
</template>
<script>
import GoodsInfo from '../../orderdetails/components/goodsInfo'
import { orderPingjia } from '@/api/order/order.js'
export default {
    components: {
        GoodsInfo,
    },
    data() {
        return {
            isDisabled: false, // 是否禁用提交按钮
            pingjiaValue: '',
            id: '',
            data: '',
            orderInfo: {
                // time: '2023-11-09 18:45:66',
                // orderId: '945656556565656568565656566',
                // imgUrl: '@/assets/images/goods.png',
                // goodsName: '得力得力顶顶顶顶顶顶顶顶顶顶的黑黑的08额外获得文化的文化文化的维护为回复u而文化氛围',
                // specifications: '1箱',
                // number: '2',
                // receiver: '冯宝宝',
                // price: '208.00',
                // goodsState: '卖家已发货',
                // phone: '15822542551',
                // address: '山东省临沂市兰山区吧啦吧啦0-10-7-9867',
                // sendMode: '商家配送',
                // driver: '王师傅',
            },
        }
    },
    mounted() {
        this.data = JSON.parse(this.$route.query.info)
        console.log(this.$route.query.info)
        this.orderInfo = this.data.item
    },
    methods: {
        // 评价订单
        submitDialog() {
            const params = {
                orderDetailId: this.data.subId,//订单明细id
                // goodsId:'',//商品id
                content: this.pingjiaValue,//评价内容
            }
            orderPingjia(params).then(res => {
                console.log(res, '订单评价')
                this.isDisabled = true
                if (res.code == 200) {
                    this.$message({
                        showClose: true,
                        message: res.message,
                        type: 'success'
                    });
                    this.$router.push({ path: '/orderlist' })
                }else{
                    this.$message({
                        showClose: true,
                        message: res.msg,
                        type: 'error'
                    });
                }
            })
        }
    }
}
</script>
<style scoped lang="less">
@import '@/assets/theme/index.less';

.container {
    width: @container-width;
    margin: 0 auto;
}

/deep/.el-textarea {
    margin: 27px 0 40px 0;
}

.el-button {
    background-color: #f23030;
    border: none;
    border-radius: 0;
    width: 172px;
    height: 51px;
    margin: 20px 0 41px 0;
}

.el-button:hover {
    background-color: #f23030;
}

.button_bottom {
    // border: #f23030 1px solid;
    display: flex;
    justify-content: flex-end;
}
</style>