import Vue from 'vue'
import Vuex from 'vuex'
import storage from '@/utils/storage'
import { login } from '@/api/user'
import { cartGoodsNum } from '@/api/shop/shop.js'
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    userinfo: '',
    carNum:0
  },
  mutations: {
    SET_USERINfO: (state, userinfo) => {
      state.userinfo = userinfo
    },
    SET_Carnum: (state, carNum) => {
      state.carNum = carNum
      console.log(state.carNum,'111212')
    }
  },
  actions: {
    getUserInfo({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(res => {
          if (res.code == 200) {
            commit('SET_USERINfO', res.data.others)
            storage.setItem('userinfo', res.data.others)
            storage.setItem('token', res.data.token);
            resolve()
          } else {
            reject(res)
          }
        })
      })
    },
    getCarNum({commit}){
      cartGoodsNum().then(res => {
        commit('SET_Carnum', res.data)
        // this.carNum = res.data
      })
    }
  }
})

export default store;

export const useStore = () => store
