<template>
    <div class="nav">
        <div class="nav_row">
            <div class="nr_arrange">
                <div> <img src="@/assets/images/icon6.png"> 全部商品分类</div>
                <div class="float" v-if="type == 0">
                    <div class="f_child" @click="click(item.id)" v-for="item, index in classData" :key="index">{{ item.title
                    }}
                        <div class="menu_san" v-if="item.children">
                            <div class="menu_s_child" @click="click(item2.id)" v-for="item2, index2 in item.children"
                                :key='index2'>{{
                                    item2.title }}
                                <div class="sc_child" v-if="item2.children">
                                    <div class="menu_s_child2" @click="click(item3.id)"
                                        v-for="item3, index3 in item2.children" :key='index3'>{{
                                            item3.title }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="n_nav" :class="{ 'on': type == 0 }">网上超市</div>
            <!-- <div class="n_nav" @click="openVn">工程类</div>
            <div class="n_nav" @click="openVn">服务类</div> -->
        </div>
    </div>
</template>
<script>
import { treeselect } from '@/api/shop/shop.js'
export default {
    name: 'navTop',
    props: {
        type: Number
    },
    data() {
        return {
            classData: null,
        }
    },

    mounted() {
        this.getTreeselect()
    },
    methods: {
        openVn() {
            const h = this.$createElement;
            this.$message({
                message: h('p', null, [
                    h('span', null, '正在开发中~ '),
                ])
            });
        },
        //获取分类
        getTreeselect() {
            treeselect().then(res => {
                console.log(res, 'res4')
                this.classData = res.data
            })
        },
        //商品详情
        toShopDetails(id) {
            this.$router.push({ path: '/shopdetails', query: { id: id } })
        },

        //商品列表
        click(e) {
            this.$router.push({ path: '/shopList', query: { nid: e } })
        },
    }
}
</script>
<style lang="less" scoped>
.nav {
    width: 100%;
    height: 50px;
    background: #EEEEEE;

    .nav_row {
        width: 1280px;
        height: auto;
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        line-height: 50px;
        text-align: center;

        .nr_arrange {
            width: 230px;
            height: 50px;
            background: #046EFF;
            color: white;
            position: relative;

            img {
                vertical-align: middle;
                width: 25px;
                height: 18px;
                margin-right: 20px;
            }

            .float {
                width: 230px;
                height: 538px;
                background: rgba(255, 255, 255, 0.88);
                display: flex;
                flex-direction: column;
                position: absolute;
                z-index: 100;

                .f_child {
                    width: 100%;
                    text-align: center;
                    color: @main-color-4;
                    font-size: @main-fontsize-1;
                    line-height: 45px;
                    cursor: pointer;

                    &:hover {
                        color: white !important;
                        background: #046EFF !important;

                        .menu_san {
                            display: block !important;
                        }

                    }

                    .menu_san {
                        position: absolute;
                        top: 0px;
                        left: 230px;
                        min-width: 230px;
                        height: 538px;
                        background: rgba(255, 255, 255, 0.88);
                        display: none;
                        cursor: pointer;
                        color: @main-color-4;
                        font-size: @main-fontsize-1;
                        line-height: 45px;

                        .menu_s_child {
                            color: @main-color-4;
                            font-size: @main-fontsize-1;
                            line-height: 45px;
                            text-align: center;

                            .sc_child {
                                position: absolute;
                                top: 0px;
                                left: 230px;
                                min-width: 230px;
                                height: 538px;
                                background: rgba(255, 255, 255, 0.88);
                                display: none;

                                .menu_s_child2 {
                                    color: @main-color-4;
                                    text-align: center;
                                    line-height: 45px;
                                    cursor: pointer;


                                    &:hover {
                                        color: white !important;
                                        background: #046EFF !important;
                                    }
                                }
                            }

                            &:hover {
                                color: white !important;
                                background: #046EFF !important;

                                .sc_child {
                                    display: block !important;
                                }
                            }
                        }
                    }
                }
            }
        }

        .n_nav {
            padding: 0 40px;
            color: #666666;
            box-sizing: border-box;
            cursor: pointer;

            &:hover {
                color: white !important;
                background: #046EFF !important;
            }

        }

        .on {
            color: white !important;
            background: #046EFF !important;
        }
    }
}
</style>