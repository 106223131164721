<template>
	<div class="order-container">
		<div class="tools">
			<a-tabs :default-active-key="pcSelfOrderStatus" @change="callback">
				<a-tab-pane key="0" :tab="'全部订单'"> <order-list :itemList="itemList" @pingjia="pingjia"
						@confirmReceipt="confirmReceipt" @confirmReceipt3="confirmReceipt3"
						@confirmReceipt2="confirmReceipt2" @deleteOrder="deleteOrder"
						@orderDetails="orderDetails"></order-list> </a-tab-pane>
				<a-tab-pane key="1" tab="待收货" force-render> <order-list :itemList="itemList"
						@confirmReceipt="confirmReceipt" @confirmReceipt2="confirmReceipt2" @deleteOrder="deleteOrder"
						@orderDetails="orderDetails"></order-list> </a-tab-pane>
				<a-tab-pane key="2" tab="待评价"> <order-list :itemList="itemList" @pingjia="pingjia"
						@confirmReceipt="confirmReceipt" @confirmReceipt2="confirmReceipt2" @deleteOrder="deleteOrder"
						@orderDetails="orderDetails"></order-list> </a-tab-pane>
				<a-tab-pane key="3" tab="售后/退货"> <order-list @confirmReceipt2="confirmReceipt2"
						@confirmReceipt3="confirmReceipt3" :itemList="itemList" @confirmReceipt="confirmReceipt"
						@deleteOrder="deleteOrder" @orderDetails="orderDetails"></order-list> </a-tab-pane>
			</a-tabs>
			<div class="tools-search">
				<!-- <input type="text" placeholder="测试" />
				<span>搜店铺</span> -->
				<el-input placeholder="请输入订单号查询" suffix-icon="el-icon-search" v-model="orderSn" @change="getOrderList('')">
				</el-input>
			</div>
			<div v-if="itemList.length == 0">
				<el-empty description="暂无订单"></el-empty>
			</div>
		</div>
		<div class="pagination" v-if="itemList.length > 0">
			<el-pagination background layout="prev, pager, next" :total="orderDate.total" @size-change="getOrderList(e)"
				@current-change="getOrderList" @prev-click="getOrderList" @next-click="getOrderList" :page-size="3">
			</el-pagination>
		</div>
	</div>
</template>
<script>
import OrderList from './components/orderlist'
import { getOrderList, delOrders, confirmReceipt } from '@/api/common/goods'
import config from '@/config/index.js'
export default {
	components: {
		OrderList,
	},
	data() {
		return {
			imgUrl: config.IMGURL,
			orderDate: {}, //订单全部数据
			itemList: [], //订单列表
			pcSelfOrderStatus: 0, //订单列表分类
			pageNum: 1, //第几页
			pageSize: 3, //每页条数
			orderSn: '',
		}
	},
	created() {
		this.pcSelfOrderStatus = this.$route.query.tabIndex ? this.$route.query.tabIndex : 0

	},
	mounted() {
		// console.log(this.$route.query.tabIndex,'路由传参')
		setTimeout(() => {
			this.getOrderList()
		}, 500)

	},
	methods: {
		// 获取订单列表
		getOrderList(e) {
			console.log(e, 'e')
			if (e) {
				this.pageNum = e
			} else {

			}
			let params = {
				orderSn: this.orderSn,//订单编号
				pcSelfOrderStatus: this.pcSelfOrderStatus,
				pageNum: this.pageNum,
				pageSize: this.pageSize,
			}
			getOrderList(params).then((res) => {
				if (res.code === 200) {
					// this.$message({
					// 	showClose: true,
					// 	message: res.message,
					// 	type: 'success'
					// });
					this.orderDate = res.data
					this.itemList = res.data.records
					console.log('订单列表', res)
				}
			})
		},
		//切换列表
		callback(e) {
			console.log('切换订单分类', e, typeof e)
			this.pcSelfOrderStatus = e
			this.pageNum = 1
			// if (e === '1') {
			// 	this.pcSelfOrderStatus = 0
			// } else if (e === '2') {
			// 	this.pcSelfOrderStatus = 1
			// } else if (e === '3') {
			// 	this.pcSelfOrderStatus = 2
			// } else if (e === '4') {
			// 	this.pcSelfOrderStatus = 3
			// } else {
			// 	this.pcSelfOrderStatus = 0
			// }
			this.getOrderList()
		},

		//订单详情
		orderDetails(id) {
			let routeUrl = this.$router.resolve({
				path: "/orderdetails",
				query: { id: id }
			});
			window.open(routeUrl.href, '_blank');
		},

		// 删除订单
		deleteOrder(id) {
			let ids = [id]
			this.$confirm('此操作将删除该订单, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				delOrders(ids).then((res) => {
					if (res.code === 200) {
						console.log('删除成功', res)
						this.getOrderList()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});
		},

		// 确认收货
		confirmReceipt(id) {
			this.$confirm('是否确认收货?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					console.log('确认收货')
					let params = {
						// id: ID,
						id: id,
					}
					confirmReceipt(params).then((res) => {
						if (res.code === 200) {
							this.$message({
								type: 'success',
								message: '操作成功!',
							})
							this.getOrderList()
						}
					})
				})
				.catch(() => {
					console.log('取消收货')
					this.$message({
						type: 'info',
						message: '已取消操作！',
					})
				})

			// let ID = Number(id)
		},

		// 删除订单
		deleteOrder(id) {
			let ids = [id]
			this.$confirm('此操作将删除该订单, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				delOrders(ids).then((res) => {
					if (res.code === 200) {
						console.log('删除成功', res)
						this.getOrderList()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});
			});

		},

		// 退货
		confirmReceipt2(id) {
			let routeUrl = this.$router.resolve({
				path: "/redundFrom",
				query: { id: id }
			});
			window.open(routeUrl.href, '_blank');
		},

		// 退货详情
		confirmReceipt3(id) {
			let routeUrl = this.$router.resolve({
				path: "/refundIndex",
				query: { id: id }
			});
			window.open(routeUrl.href, '_blank');
		},

		pingjia(data) {
			let routeUrl = this.$router.resolve({
				path: "/orderEvaluate",
				query: {
					info: JSON.stringify(data)
				}
			});
			window.open(routeUrl.href, '_blank');
			// this.$router.push({ path: '/orderEvaluate', query: { data: data } })
		}
	},
}
</script>
<style lang="less" scoped>
@import 'index.less';

.pagination {

	display: flex;
	justify-content: flex-end;
	margin: 50px 0;
}
</style>