var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"allshop-container"},[_vm._m(0),_c('div',{staticClass:"shop"},[_c('div',{staticClass:"shop-item"},[_c('img',{attrs:{"src":require('@/assets/images/shop.png'),"alt":""}}),_c('h3',[_vm._v("店铺名称")]),_c('p',[_vm._v("主营:类目类目名称,类名2")]),_vm._m(1),_c('span',{staticClass:"shop-item-unfocus"},[_vm._v("取消关注")])]),_c('div',{staticClass:"shop-item"},[_c('img',{attrs:{"src":require('@/assets/images/shop.png'),"alt":""}}),_c('h3',[_vm._v("店铺名称")]),_c('p',[_vm._v("主营:类目类目名称,类名2")]),_vm._m(2),_c('span',{staticClass:"shop-item-unfocus"},[_vm._v("取消关注")])]),_c('div',{staticClass:"shop-item"},[_c('img',{attrs:{"src":require('@/assets/images/shop.png'),"alt":""}}),_c('h3',[_vm._v("店铺名称")]),_c('p',[_vm._v("主营:类目类目名称,类名2")]),_vm._m(3),_c('span',{staticClass:"shop-item-unfocus"},[_vm._v("取消关注")])]),_c('div',{staticClass:"shop-item"},[_c('img',{attrs:{"src":require('@/assets/images/shop.png'),"alt":""}}),_c('h3',[_vm._v("店铺名称")]),_c('p',[_vm._v("主营:类目类目名称,类名2")]),_vm._m(4),_c('span',{staticClass:"shop-item-unfocus"},[_vm._v("取消关注")])]),_c('div',{staticClass:"shop-item"},[_c('img',{attrs:{"src":require('@/assets/images/shop.png'),"alt":""}}),_c('h3',[_vm._v("店铺名称")]),_c('p',[_vm._v("主营:类目类目名称,类名2")]),_vm._m(5),_c('span',{staticClass:"shop-item-unfocus"},[_vm._v("取消关注")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tools"},[_c('span',{staticClass:"tools-number"},[_vm._v("全部店铺(20)")]),_c('div',{staticClass:"tools-search"},[_c('input',{attrs:{"type":"text","placeholder":"测试"}}),_c('span',[_vm._v("搜店铺")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-item-btn"},[_c('span',[_vm._v("进入店铺")]),_c('span',[_vm._v("联系电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-item-btn"},[_c('span',[_vm._v("进入店铺")]),_c('span',[_vm._v("联系电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-item-btn"},[_c('span',[_vm._v("进入店铺")]),_c('span',[_vm._v("联系电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-item-btn"},[_c('span',[_vm._v("进入店铺")]),_c('span',[_vm._v("联系电话")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"shop-item-btn"},[_c('span',[_vm._v("进入店铺")]),_c('span',[_vm._v("联系电话")])])
}]

export { render, staticRenderFns }