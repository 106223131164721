<template>
	<div>
		<table class="order-container">
			<thead>
				<tr>
					<th class="th-one">订单详情</th>
					<th class="th-two">收货人</th>
					<th class="th-three">金额</th>
					<th class="th-four">状态</th>
					<th class="th-five">操作</th>
				</tr>
			</thead>
		</table>
		<OrderItem :goodsList="itemList" @confirmReceipt3="confirmReceipt3" @confirmReceipt="confirmReceipt" @confirmReceipt2="confirmReceipt2" @deleteOrder="deleteOrder" @orderDetails="orderDetails" @pingjia="pingjia"></OrderItem>
	</div>
</template>
<script>
import OrderItem from '../orderitem'
export default {
	components: {
		OrderItem,
	},
	props: {
		itemList: {
			type: Array,
			default: function () {
				return []
			},
		},

		orderType: {
			type: Number,
			default: 1,
		},
	},
	data() {
		return {
			goodsList: [
				{
					time: '2023-11-09 18:45:66',
					orderId: '945656556565656568565656566',
					imgUrl: '@/assets/images/goods.png',
					goodsName: '得力得力顶顶顶顶顶顶顶顶顶顶的黑黑的08额外获得文化的文化文化的维护为回复u而文化氛围',
					specifications: '1箱',
					number: '2',
					receiver: '冯宝宝',
					price: '208.00',
					goodsState: '卖家已发货',
				},
				{
					time: '2023-11-09 18:45:66',
					orderId: '945656556565656568565656566',
					imgUrl: '@/assets/images/goods.png',
					goodsName: '得力得力顶顶顶顶顶顶顶顶顶顶',
					specifications: '1箱',
					number: '2',
					receiver: '冯宝宝',
					price: '208.00',
					goodsState: '卖家已发货',
				},
			], //订单列表
		}
	},
	methods: {
		//订单详情
		orderDetails(id) {
			this.$emit('orderDetails',id)
		},
		//删除订单
		deleteOrder(id) {
			this.$emit('deleteOrder',id)
		},
		//确认收货
		confirmReceipt(id) {
			this.$emit('confirmReceipt',id)
		},
		//退货
		confirmReceipt2(id) {
			this.$emit('confirmReceipt2',id)
		},
		//退货详情
		confirmReceipt3(id) {
			this.$emit('confirmReceipt3',id)
		},
		//点击评价
		pingjia(data){
			this.$emit('pingjia',data)
		}
	}
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>