<template>
  <div>
		<table class="order-container">
			<thead>
				<tr>
					<th class="th-one">商品名称</th>
					<th class="th-two"></th>
					<th class="th-three">购买数量</th>
					<th class="th-four">金额</th>
				</tr>
			</thead>
      <tbody>
        <tr class="temp">
        </tr>
        <tr class="first">
          <td>{{ goodsInfo.createdTime }}</td>
          <td class="first-2">订单号：{{ goodsInfo.orderSn }}</td>
          <td></td>
          <td></td>
        </tr>
        <tr class="second">
          <td class="second-1" >
            <img :src="imgUrl+goodsInfo.subPicUrl" alt="" width="150" height="120">
          </td>
          <td class="second-2">
            <div class="goods-name">{{ goodsInfo.subGoodsName }}</div>
            <div class="specifications">产品规格：{{ goodsInfo.subSpecifications }}</div>
          </td>
          <td class="second-3">{{ goodsInfo.subNumber }}件</td>
          <td class="second-4">￥{{ goodsInfo.actualPrice }}</td>
        </tr>
      </tbody>
		</table>
	</div>
</template>

<script>
import config from '@/config/index.js'
export default {
  props: {
    goodsInfo: {
      type: Object,
      default: {},
      // required:true
    },
  },
  data() {
    return {
      imgUrl: config.IMGURL,
    }
  },
}

</script>

<style lang="less" scoped>
@import url('./index.less');
</style>