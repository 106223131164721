<template>
	<div class="body">
		<div class="empty" v-if="goodsList.length < 1"><el-empty description="空空如也~"></el-empty></div>
		<div v-else>
			<h1>
				全部商品<span>{{ carDates.goodsNum }}</span
				>件
			</h1>
			<div class="cart-container" ref="cartContainer">
				<!-- @scroll="handleScroll" -->
				<table>
					<thead>
						<tr>
							<th class="th-1">
								<input type="checkbox" v-model="carDates.checked" @change="selectAllItems" />
							</th>
							<th class="th-2"><span>全部</span></th>
							<th class="th-3">
								<span class="goods-info">商品信息</span>
								<span class="goods-guige">规格</span>
							</th>
							<th class="th-4">单价（元）</th>
							<th class="th-5">数量</th>
							<th class="th-6">小计</th>
							<th class="th-7">操作</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(item, index) in goodsList" :key="index">
							<td class="td-1">
								<input type="checkbox" v-model="item.checked" @change="selectItem(item)" />
							</td>
							<td class="td-2">
								<img :src="imgUrl + item.url" alt="" width="87" height="87" />
							</td>
							<td class="td-3">{{ item.goodsName }}{{ item.simpleSpecs }}</td>
							<td class="td-4">￥{{ item.purchasePrice }}</td>
							<td class="td-5"><el-input-number v-model="item.num" @change="handleChange(item)" :min="1" :max="100" size="mini"></el-input-number>{{ item.unitName }}</td>
							<td class="td-6">￥{{ item.subTotal }}</td>
							<td class="td-7">
								<button @click="deleteItem(item)"><i class="el-icon-delete"></i>删除</button>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="gap"></div>
			<div class="foot">
				<div class="foot-left">
					<div class="all-check">
						<input type="checkbox" v-model="carDates.checked" @change="selectAllItems" />
						<span>全选</span>
					</div>
					<button @click="deleteSelectedItems">删除选中</button>
					<button @click="deleteSelectedStatus">快速清除</button>
				</div>
				<div class="foot-right">
					<div class="check-num">
						已选<span class="num">{{ carDates.checkedNum }}</span
						>件商品
					</div>
					<div class="total">
						总价：<span class="symbol"
							>￥<span class="price">{{ carDates.checkedMoney }}</span></span
						>
					</div>
					<div class="account">
						<button @click="goAccount">去结算<i class="el-icon-arrow-right"></i></button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import { getCarList, updateChecked, updateNum, deleteGoods } from '@/api/common/goods.js'
import config from '@/config/index.js'
export default {
	data() {
		return {
			imgUrl: config.IMGURL,
			num: 1, //商品数量
			carDates: {}, //购物车数据
			goodsList: [], //购物车商品列表
		}
	},
	mounted() {
		// 从接口获取商品数据
		this.getCarList()
	},
	methods: {
		//获取购物车详情
		getCarList() {
			getCarList().then((res) => {
				if (res.code === 200) {
					this.carDates = res.data
					this.goodsList = res.data.skuList
					console.log('购物车详情', this.carDates, this.goodsList)
				}
			})
		},
		//更新选中状态
		updateChecked(item) {
			console.log('选中状态', item.skuId, item.checked)
			let params = {
				checked: item.checked,
				skuIds: item.skuId,
			}
			updateChecked(params).then((res) => {
				console.log('更新状态成功')
				// 刷新购物车数据
				this.getCarList()
			})
		},
		//删除商品更新
		deleteGoods(item) {
			console.log('删除', item.skuId)
			// let skuIds = item.skuId
			// let params = {
			// 	skuIds: item.skuId,
			// }
			let skuIds = [item.skuId]
			this.$confirm('此操作将删除该商品, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					deleteGoods(skuIds).then((res) => {
						// 刷新购物车数据
						this.getCarList()
						this.$store.dispatch('getCarNum')
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
					})
				})
		},
		//全选
		async selectAllItems() {
			for (const item of this.goodsList) {
				await new Promise((resolve, reject) => {
					//发送数据请求
					item.checked = this.carDates.checked
					let params = {
						checked: item.checked,
						skuIds: item.skuId,
					}
					updateChecked(params)
						.then(() => {
							// 数据发送成功后，执行resolve()来继续遍历下一项
							resolve()
						})
						.catch((error) => {
							// 数据发送失败时，执行reject()来中止循环
							console.log('全选失败', item)
							reject(error)
						})
				})
			}
			// 遍历完成刷新购物车数据
			this.getCarList()
		},
		// 商品选中状态
		selectItem(item) {
			if (!item.checked) {
				this.carDates.checked = false
			} else {
				this.carDates.checked = this.goodsList.every((item) => item.checked)
			}
			this.updateChecked(item)
			// 刷新购物车数据
			// this.$nextTick(() => {
			// 	this.getCarList()
			// })
		},
		//商品数量
		handleChange(item) {
			let params = {
				num: item.num,
				skuId: item.skuId,
			}
			updateNum(params).then((res) => {
				console.log('更新数量', res)
				// 刷新购物车数据
				this.getCarList()
			})
		},
		// 删除单个商品
		deleteItem(item) {
			console.log('商品删除', item, item.skuId)
			// this.goodsList = this.goodsList.filter((i) => i.skuId !== item.skuId)
			this.deleteGoods(item)
		},
		//删除所选商品
		async deleteSelectedItems() {
			let skuIds = []
			let goodsDeleteList = this.goodsList.filter((item) => item.checked) //筛选出已选的商品放进数组
			for (const item of goodsDeleteList) {
				// await new Promise((resolve, reject) => {
				skuIds.push(item.skuId)

				// 		.then(() => {
				// 			// 数据发送成功后，执行resolve()来继续遍历下一项
				// 			resolve()
				// 		})
				// 		.catch((error) => {
				// 			// 数据发送失败时，执行reject()来中止循环
				// 			console.log('全选失败', item)
				// 			reject(error)
				// 		})
				// })
			}
			// 遍历完成
			this.$confirm('此操作将删除所选商品, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					console.log('数组参数',skuIds)
					// let params = {
					// 	skuIds: skuIds,
					// }
					deleteGoods(skuIds).then((res) => {
						// 遍历完成刷新购物车数据
						this.$store.dispatch('getCarNum')
						this.getCarList()
					})
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除',
					})
				})
		},
		// 清除商品选择状态
		async deleteSelectedStatus() {
			for (const item of this.goodsList) {
				await new Promise((resolve, reject) => {
					//发送数据请求
					item.checked = false
					let params = {
						checked: item.checked,
						skuIds: item.skuId,
					}
					updateChecked(params)
						.then(() => {
							// 数据发送成功后，执行resolve()来继续遍历下一项
							resolve()
						})
						.catch((error) => {
							// 数据发送失败时，执行reject()来中止循环
							console.log('全选失败', item)
							reject(error)
						})
				})
			}
			// 遍历完成刷新购物车数据
			this.getCarList()
		},
		//提示
		openTip() {
			this.$message({
				message: '请先选择商品再结算哦~',
				type: 'warning',
			})
		},
		//去结算
		goAccount() {
			let list = []
			let isEmpty = true //是否没有商品选中
			this.goodsList.forEach((item, index) => {
				if (item.checked === true) {
					list[index] = { num: item.num, skuId: item.skuId }
					isEmpty = false
				}
			})
			// 去除空项
			list = list.filter((item) => item !== '')
			console.log('去结算', list)
			if (isEmpty) {
				this.openTip()
			} else {
				console.log('去结算qqqq', list, JSON.stringify(list))
				this.$router.push({
					path: '/account',
					query: {
						cartType: 'CART',
						shopOerderAddDTOList: JSON.stringify(list),
					},
				})
			}
		},
	},
}
</script>
  
<style lang="less" scoped>
@import 'index.less';
</style>