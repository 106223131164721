<template>
	<div>
		<div class="flex marignTop22" v-for="(item,index ) in evaluation.records" :key="index">
			<div class="flexX">
				<div class="center">
					<img src="../../../assets/images/user.png" alt="" />
					<div class="marignTop6">{{item.memberName}}</div>
				</div>
				<div>
					<div class="marignleft40">
						<div class="details">{{item.content}}</div>
						<div class="marignTop6 time">{{item.updatedTime}}</div>
					</div>
					<div class="marignleft40 adminHuifu">
						回复：{{item.adminContent}}
					</div>
				</div>
			</div>
			
			
			<div class="pingjiaRight">
				<div>{{item.specifications}}</div>
				<!-- <div>CPU型号：海光 3250</div>
				<div>内存容量：8G</div>
				<div>型号：信号机H880-T1</div>
				<div>内存容量：8G</div>
				<div>内存容量：8G</div> -->
			</div>
			
		</div>
		<div v-if="evaluation.total == 0">
			<el-empty description="暂无评价"></el-empty>
		</div>
		<div class="pagination marignTop36" v-if="evaluation.total> 0">
            <el-pagination background layout="prev, pager, next" :total="evaluation.total" @size-change="goodsPingjiaFenye(e)" @current-change="goodsPingjiaFenye"
				@prev-click="goodsPingjiaFenye" @next-click="goodsPingjiaFenye"> 
            
            </el-pagination>
        </div>
	</div>
</template>
<script>
export default {
	name: 'App',
	props: {
		evaluation:{
			type: Object,
			default: () => {}
		},
		avatar: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			default: '',
		},
		specifications: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {}
	},
	mounted() {
		// 初始化
		// this.init();
	},
	methods: {
		goodsPingjiaFenye(e){
			this.$emit('goodsPingjiaFenye',e)
		}
	},
}
</script>
<style lang="less">
@import '../index.less';
.pingjiaRight {
	border-left: 1px solid #e5e5e5;
	// padding-left: 20px;
	padding: 15px 0 10px 20px;
}
.details {
	font-size: @main-fontsize-1;
	font-weight: 600;
}
.pagination{

    display: flex;
    justify-content: flex-end;
}
</style>