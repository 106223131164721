/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'; // 引入axios
import vm from '../main'
import storage from "@/utils/storage"
axios.defaults.baseURL = process.env.VUE_APP_BASE_URL//
// axios.defaults.baseURL = ''
/**
 * 提示函数
 * 禁止点击蒙层、显示一秒后关闭
 */
const tip = msg => {
    vm.$message.error(msg)
}
/**
 * 跳转登录页
 * 携带当前页面路由，以期在登录页面完成登录后返回当前页面
 */
const toLogin = () => {
    if (vm.$route.name !== 'login') {
        vm.$message.warning({
            content: '身份认证信息已失效，请重新登录。',
            duration: 2,
            onClose: () => {
                storage.clearItem('token')
                storage.clearItem('userinfo')
                vm.$router.push({ name: 'login' })
            }
        })
    }
}

/**
 * 请求失败后的错误统一处理
 * @param {Number} status 请求失败的状态码
 */
const errorHandle = (status, data, message) => {
    console.log(status,'sdahjshdasuhdkas')
    // 状态码判断
    switch (status) {

        case 401: // 401: 未登录状态，跳转登录页
            tip('请先登录');
            setTimeout(() => toLogin(), 1000);
            break;
        case 403: // 403 token过期 清除token并跳转登录页
            tip('登录过期，请重新登录');
            setTimeout(() => toLogin(), 1000);
            break;
        case 404:// 404请求不存在
            tip('请求的资源不存在');
            setTimeout(() => toLogin(), 1000);
            break;
        default:
            // 未定义错误状态
            switch (data.code) {
                default:
                //tip(message);
            }
    }
}

// 创建axios实例
var instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: 1000 * 60,
    responseType: "json",
    withCredentials: false, // 是否允许带cookie这些
    headers: {
        "Accept": "application/json, text/plain, */*",
        "Content-Type": "application/json;charset=utf-8"
    }
});

var currentRequest = 0;
/**
 * 请求拦截器
 * 每次请求前，如果存在token则在请求头中携带token
 */
instance.interceptors.request.use(
    config => {
        config.headers.Authorization = storage.getItem('token') || ''
        config.headers.authType = 1
        // config.headers.Authorization = 'eyJhbGciOiJIUzUxMiJ9.eyJsb2dpbl91c2VyX2tleSI6ImQ2NzljYTFjLTg4YmItNDhiOC1iOWVlLWZkMGUzZmQ2YzU2YSJ9.hesSSB_FouP1SPrBRMsk4qpJyHlUuY1wea4C0v2g4_8nnNlyWljpxsHCuZ9x2XW4xZNhyLAbl45nJQuNVqINbw'
        return config;
    },
    error => {
        return Promise.error(error);
    })

// 响应拦截器
instance.interceptors.response.use(
    response => {
        console.log('response', response)
        if (response.status === 200) {
            if(response.data.code == 401){
                tip('请先登录');
                setTimeout(() => toLogin(), 1000);
                return;
            }
            if (response.config.responseType == 'blob') {
                return Promise.resolve(response)
            } else {
                return Promise.resolve(response.data)
            }
            //  return Promise.resolve(response.data)
        } else {
            tip(response.data.message);
            return Promise.reject(response)
        }
    },
    // 服务器状态码不是200的情况
    error => {
        console.log('error', error)
        const { response } = error;
        if (response) {
            if (process.env.VUE_APP_DEBUG == 'true') {
                console.error(`${response.config.method} ${response.config.url} ${response.status}`);
                console.error("params: " + response.config.data);
                console.error("data: " + JSON.stringify(response.data));
            }
            // 请求已发出，但是不在2xx的范围
            errorHandle(response.status, response.data, response.data ? response.data.message : '服务异常');
            // setTimeout(() =>  Toast.clear(), 500);
            return Promise.reject(response);
        } else {
            // 处理断网的情况
            // eg:请求超时或断网时，更新state的network状态
            // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
            // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
            //store.commit('changeNetwork', false);
            return Promise.reject(response);
        }
    }
);

export default instance;
