<template>
	<div>
		<!-- <el-row style="width: 100%" :gutter="8"> -->
			<!-- <el-col :span="5"> -->
				<el-select ref="firstSelect" :value="firstValue" placeholder="选择省" @change="changeFirst" :label-in-value="true" class="select">
					<el-option v-for="(item, index) in firstData" :key="'first-' + index" :value="item.id" :label="item.name" :label-in-value="true"> </el-option>
				</el-select>
			<!-- </el-col> -->
			<!-- <el-col :span="5"> -->
				<el-select ref="secondSelect" :value="secondValue" placeholder="选择市" :clearable="true" @change="changeSecond" :label-in-value="true" class="select">
					<el-option v-for="(item, index) in secondData" :key="'second-' + index" :value="item.id" :label="item.name">{{ item.name }} </el-option>
				</el-select>
			<!-- </el-col> -->
			<!-- <el-col :span="5"> -->
				<el-select ref="thirdSelect" :value="thirdValue" placeholder="选择县" :clearable="true" @change="changeThird" :label-in-value="true" class="select">
					<el-option v-for="(item, index) in thirdData" :key="'third-' + index" :value="item.id" :label="item.name">{{ item.name }} </el-option>
				</el-select>
			<!-- </el-col> -->

			<!-- <el-col :span="5"> -->
				<el-select ref="fourthSelect" :value="fourthValue" placeholder="选择街道" :clearable="true" @change="changeFourth" :label-in-value="true" class="street">
					<el-option v-for="(item, index) in fourthData" :key="'fourth-' + index" :value="item.id" :label="item.name">{{ item.name }} </el-option>
				</el-select>
			<!-- </el-col>
		</el-row> -->
	</div>
</template>
<script>
export default {
	name: 'CitySelect',
	props: {
		initData: {
			type: Array,
			default() {
				return []
			},
		},
		firstApi: {
			type: Function,
			default(v) {
				return function () {}
			},
		},
		secondApi: {
			type: Function,
			default(v) {
				return function () {}
			},
		},
		thirdApi: {
			type: Function,
			default() {
				return function () {}
			},
		},
		fourthApi: {
			type: Function,
			default() {
				return function () {}
			},
		},
	},
	data() {
		return {
			firstData: [],
			firstValue: '',
			firstLabe: '',

			secondData: [],
			secondValue: '',
			secondLabe: '',

			thirdData: [],
			thirdValue: '',
			thirdLabe: '',

			fourthData: [],
			fourthValue: '',
			fourthLabe: '',

			successData: [],
			isSelect: false,
		}
	},
	mounted() {
		this.initCompont()
	},
	methods: {
		//初始化第一个下拉框
		initCompont() {
			//获取第一个下拉框的数据
			this.getFirsData()
			if (this.initData.length > 0) {
				this.firstValue = this.initData[0].id
				this.firstLabe = this.initData[0].name
				if (this.initData[1]) {
					//获取二级联动
					this.getSecondData(this.firstValue)
					this.secondValue = this.initData[1].id
					this.secondLabe = this.initData[1].name
				}

				if (this.initData[2]) {
					//获取三级联动
					this.getThirdData(this.secondValue)
					this.thirdValue = this.initData[2].id
					this.thirdLabe = this.initData[2].name
				}
				if (this.initData[3]) {
					//获取四级联动
					this.getFourthData(this.thirdValue)
					this.fourthValue = this.initData[3].id
					this.fourthLabe = this.initData[3].name
				}
			}
		},
		getFirsData() {
			this.firstApi().then((result) => {
				this.firstData = result.data
				if (result.data.length == 0) {
					this.successSelect()
				}
			}).catch((err) => {

            })
		},
		//触发第一个下拉框
		changeFirst(option) {
			this.firstLabe = this.firstData.find((item) => item.id === option).name
			this.firstValue = option

			this.secondValue = ''
			this.secondLabe = ''

			this.thirdValue = ''
			this.thirdLabe = ''

			this.fourthValue = ''
			this.fourthLabe = ''
            console.log(option, 'option')
			//加载第二个下拉框数据
			this.getSecondData(this.firstValue)
		},
		//获取第二个下拉框数据
		getSecondData(value) {
			// this.$refs.fourthSelect.clearSingleSelect() //清空表单选项
			// this.$refs.thirdSelect.clearSingleSelect() //清空表单选项
			// this.$refs.secondSelect.clearSingleSelect() //清空表单选项
			this.secondData = []
			this.thirdData = []
			this.fourthData = []
			this.secondApi({ parentId: value }).then((result) => {
				this.secondData = result.data
				if (result.data.length == 0) {
					this.successSelect()
				}
			}).catch((err) => {})
		},
		//触发第二个下拉框
		changeSecond(option) {
			if (option) {
				this.secondLabe = this.secondData.find((item) => item.id === option).name
				this.secondValue = option

				this.thirdValue = ''
				this.thirdLabe = ''

				//加载第三个下拉框数据
				this.getThirdData(this.secondValue)
			}
		},
		//获取第三个下拉框数据
		getThirdData(value) {
			// this.$refs.thirdSelect.clearSingleSelect() //清空表单选项
			this.thirdData = []
			this.fourthData = []
			if (value != null && value != undefined && value != '') {
				this.thirdApi({ parentId: value }).then((result) => {
						this.thirdData = result.data
						if (result.data.length == 0) {
							this.successSelect()
						}
					})
					.catch((err) => {})
			}
		},
		//触发第三个下拉框
		changeThird(option) {
			if (option) {
				if (option) {
					this.thirdLabe = this.thirdData.find((item) => item.id === option).name
					this.thirdValue = option

					this.fourthValue = ''
					this.fourthLabe = ''
					//加载第三个下拉框数据
					this.getFourthData(this.thirdValue)
				}
			}
		},
		//获取第四个下拉框数据
		getFourthData(value) {
			this.fourthData = []
			if (value != null && value != undefined && value != '') {
				this.fourthApi({ parentId: value }).then((result) => {
						this.fourthData = result.data
						// if (result.data.length == 0) {
							this.successSelect()
						// }
					}).catch((err) => {})
			}
		},

		//触发第四个下拉框
		changeFourth(option) {
			if (option) {
				this.fourthLabe = this.fourthData.find((item) => item.id === option).name
				this.fourthValue = option
				this.successSelect('fourth')
			}
		},
		successSelect() {
			const arr = [
				{
					id: this.firstValue,
					name: this.firstLabe,
				},
				{
					id: this.secondValue,
					name: this.secondLabe,
				},
				{
					id: this.thirdValue,
					name: this.thirdLabe,
				},
				{
					id: this.fourthValue,
					name: this.fourthLabe,
				},
			]
			this.$emit('success-select', arr)
		},
	},
}
</script>
<style scoped lang="less">
/deep/.el-select{
    // width: 100px;
    margin-right: 0;
}
.select{
    margin-right: 10px;
     width: 95px;
}
.street{
    width: 136px;
}
</style>