<template>
	<div>
		<div class="flex nav_top">
			<div class="shopName">
				<img src="../../assets/images/icon13.jpg" />
				{{ store.storeName }}
			</div>
			<div class="imgs">
				<div class="i_left" @click="addShopFollow" v-if="!store.isAttentionStores">
					<img src="@/assets/images/guanzhu.png" alt="">关注店铺
				</div>
				<div class="i_left" @click="deleteFollow" v-if="store.isAttentionStores">
					<img src="@/assets/images/yiguanzhu.png" alt="">取消关注
				</div>
				<!-- <img src="@/assets/images/bg1.png"> -->
				<div>小程序店铺</div>
			</div>
		</div>
		<div class="t_img" v-if="store.storesAdItem.imgUrl">
			<img :src="imgUrl + store.storesAdItem.imgUrl" :onerror="defaultImg">
		</div>
		<div class="nav">
			<div class="nav_row">
				<div class="n_nav" @click="click(1)">
					本店所有商品
				</div>
				<div class="n_nav" @click="click(2)">店铺信息</div>
			</div>
		</div>
		<div class="shop">
			<div class="shop_left_c">
				<div id="goodsPics">
					<!-- 当前查看商品图区域 -->
					<div id="imgPre" style="position: relative;" @mouseleave="seeEnd">
						<div ref="imgPre">
							<el-image :src="url" fit="cover" class="imgYuan"></el-image>
							<!-- <img :src="url" alt="picNow" class="imgYuan"> -->
						</div>

						<div class="topMask" @mouseenter="seeBegin($event)" @mousemove="move($event)">
							<!-- 鼠标放大镜模块 -->
							<div ref="move" v-show="isShow" class="move" :style="cursorMask">
							</div>
						</div>

						<!-- 商品图总览，就是一排的小图 -->
						<div class="shop_left_bottom">
							<i class="el-icon-arrow-left" style="font-size:28px"></i>
							<div class="imgflex">
								<ul class="imgNow">
									<li v-for="(item, index) in shopDetails.galleryArr" :key="item.id"
										@mouseover="seeThis(index)" :id="urlIndex == index ? 'sign' : ''">
										<!-- <img style="width: 54px; height: 54px;" :src="imgUrl +item" alt="pics"> -->
										<el-image style="width: 54px; height: 54px;" :src="imgUrl + item" fit="cover"
											class=""></el-image>
									</li>
								</ul>
							</div>
							<i class="el-icon-arrow-right" style="font-size:28px"></i>
						</div>

						<!-- 放大镜区域，查看商品图放大后的效果 -->
						<div v-if="isShow" id="seeDetail" ref="bigImg">
							<el-image :src="Bigurl" fit="cover" :style="imgMove" class="bigImg"></el-image>
							<!-- <img :src="Bigurl" alt="" :style="imgMove"> -->
						</div>
					</div>
				</div>
			</div>
			<div class="shop_right">
				<div class="shop_right1">{{ shopDetails.goodsName }}</div>
				<div class="shop_right_value marignTop36 lineHeight36">
					销售价：
					<div class="price">￥ {{ shopGoodsSkuDefault.price }}</div>
				</div>
				<div class="shop_right_value marignTop22 flexalign" v-for="(item, index) in shopDetails.goodsSkuVOList"
					:key="index">
					<div class="width65">{{ item.specificationName }}</div>
					<!--  -->
					<div class="flexcenter conmmdborder" :class="[it.checked ? 'border' : 'unborder']"
						v-for="(it, idx) in item.list" :key="idx" @click="specNameClick(it.id, item.id)">
						{{ it.specificationValueName }}
					</div>
				</div>

				<div class="shop_right_value marignTop22 flexalign ">
					<div class="width65">数量</div>
					<div class="flexcenter marignleft20">
						<el-input-number v-model="num" :min="1" size="small" />
						<div class="marignleft12">库存{{ shopGoodsSkuDefault.number }}件</div>
					</div>
				</div>


				<div style="margin:18px 0 0 85px">
					<el-button plain size="small" class="marignTop22 shopcarButton" @click="getAddCart"
						:disabled="shopGoodsSkuDefault.number == 0 ? true : false">加入购物车</el-button>
					<el-button plain size="small" class="marignTop22 payButton"
						:disabled="shopGoodsSkuDefault.number == 0 ? true : false" @click="payNowClick">立即购买</el-button>
				</div>
			</div>
		</div>

		<div class="shop_details">
			<div class="shop_details_left">
				<Merchant :name="store.storeName" :imgUrl="imgUrl + store.avatar" :store="store" v-if="store"></Merchant>
			</div>
			<div class="shop_details_right marignleft20">
				<div class="el-menu-demo flexX">
					<div class="shop_details_right_t_item flexcenter" :class="[jieshaoPingjiaShow ? 'active' : '']"
						@click="shopJP(1)">产品介绍</div>
					<div class="shop_details_right_t_item flexcenter" :class="[jieshaoPingjiaShow ? '' : 'active']"
						@click="shopJP(2)">用户评价</div>
				</div>
				<div class="jieshao_pingjia" v-show="jieshaoPingjiaShow">
					<!-- <div class="shop_jieshao flexW">
						<div class="width20 marignTop33">
							产品编号：1111111111111
						</div>
						<div class="width20 marignTop33">
							产品编号：1111111111111
						</div>
						<div class="width20 marignTop33">
							产品编号：1111111111111
						</div>
						<div class="width20 marignTop33">
							产品编号：1111111111111
						</div>
						<div class="width20 marignTop33">
							产品编号：1111111111111
						</div>
					</div> -->
					<div class="shop_jieshao_title">
						产品介绍
					</div>
					<div v-html="shopDetails.detail"></div>
				</div>

				<div class="jieshao_pingjia" v-show="!jieshaoPingjiaShow">
					<div class="shop_jieshao_title">
						用户评价
					</div>
					<div class="heightBg"></div>
					<div>
						<Evaluation :evaluation="goodsEvaluation" @goodsPingjiaFenye="goodsPingjiaFenye"></Evaluation>
					</div>
				</div>

			</div>
		</div>
	</div>
</template>
<script>
import Evaluation from './components/evaluation.vue' //评价组件
import Merchant from './components/merchant.vue' //商家组件
import { shopGoodsDetails, goodsEvaluation, skulist, addCart } from '@/api/shop/shop.js'
import { addShopFollow, deleteFollow } from '@/api/user/index.js'
import config from '@/config/index.js'
export default {
	components: {
		Merchant,
		Evaluation
	},
	data() {
		return {
			defaultImg: 'this.src = "' + require('@/assets/images/err3.png') + '"', //默认轮播图片的地址
			imgUrl: config.IMGURL,
			// 数据
			num: '1',
			activeIndex: '1',
			url: '',
			Bigurl: '',
			urlIndex: 0,//
			isShow: 0,
			cursorMask: { transform: '' },
			imgMove: { transform: '' },

			jieshaoPingjiaShow: true,

			shopDetails: '',//商品详情
			shopGoodsSkuDefault: {
				number: 0,
				price: 0,
			},//商品规格默认数据
			store: '',//店铺数据

			page: {
				pageNum: 1,
				pageSize: 10
			},
			goodsEvaluation: {},//商品评价
		}
	},
	mounted() {
		this.getGoodsDetails()


	},
	methods: {
		//获取商品详情
		getGoodsDetails() {
			let that = this;
			shopGoodsDetails(this.$route.query.id).then(res => {
				if (res.code == 200) {
					//进入页面请求评价数据
					that.getgoodsEvaluation(res.data.id)
					//给商品详情赋值
					this.shopDetails = res.data
					//商品图片预览
					if (res.data.galleryArr != null) {
						this.url = that.imgUrl + res.data.galleryArr[0]
					}
					// 默认商品规格信息
					if (res.data.shopGoodsSkuDefault) {
						this.shopGoodsSkuDefault = res.data.shopGoodsSkuDefault
					}
					//店铺数据
					this.store = res.data.store
					console.log(this.store, 'this.store')
					this.shopDetails.goodsSkuVOList.forEach(element => {
						element.list.forEach(item => {
							if (res.data.shopGoodsSkuDefault) {
								if (this.shopDetails.shopGoodsSkuDefault.specificationArr.includes(item.id)) {
									that.$set(item, 'checked', true)
								} else {
									that.$set(item, 'checked', false)
								}
							}
						})
					});
					console.log(this.shopDetails, '商品详情');
					this.$forceUpdate()
				} else {
					this.$message({
						message: '请求失败',
						type: 'warning'
					});
				}
			})
		},

		addShopFollow() {
			addShopFollow({
				remark: '',
				storeId: this.store.id
			}).then(res => {
				this.$message({
					message: '关注成功~',
					type: 'success'
				});
				setTimeout(() => {
					location.reload()
				}, 2000);
				console.log(res)
			})
		},

		deleteFollow() {
			deleteFollow(this.store.id).then(res => {
				this.$message({
					message: '取消成功~',
					type: 'success'
				});
				setTimeout(() => {
					location.reload()
				}, 2000);
				console.log(res)
			})
		},

		goodsPingjiaFenye(e) {
			// console.log(e,'asdkjahskh');
			this.page.pageNum = e
			this.getgoodsEvaluation(this.shopDetails.id)
		},

		//商品评价
		getgoodsEvaluation(id) {
			const params = {
				...this.page,
				goodsId: id
			}
			// console.log(11111,params)
			goodsEvaluation(params).then(res => {
				// console.log(res,'商品评价');
				if (res.code == 200) {
					this.goodsEvaluation = res.data
				}
			})
		},

		//查询商品规格
		getSkulist(specificationArr) {
			const params = {
				goodsId: this.shopGoodsSkuDefault.goodsId,
				specificationArr: specificationArr,
			}
			skulist(params).then(res => {
				// console.log(res,'规格');
				if (res.code == 200) {
					this.shopDetails.galleryArr = []
					this.shopDetails.galleryArr.push(res.data.skuVOList[0].url)
					this.url = this.imgUrl + res.data.skuVOList[0].url
					console.log(this.url)
					if (res.data.skuVOList.length > 0) {

						this.shopGoodsSkuDefault = res.data.skuVOList[0]
					} else {
						this.shopGoodsSkuDefault.price = 0
						this.shopGoodsSkuDefault.number = 0
					}
				}
			})
		},
		//点击某一个规格值
		specNameClick(id, specificationNameId) {
			// console.log(id,specificationNameId,'点击规格');
			const that = this
			const specificationArr = []
			this.shopDetails.goodsSkuVOList.forEach(item => {
				item.list.forEach(element => {
					if (element.id == id && specificationNameId == item.id) {
						element.checked = true//!element.checked
						// console.log(this.shopDetails,'商品详情切换');
					} else if (element.id != id && specificationNameId == item.id) {
						element.checked = false
					}
					if (element.checked) {
						specificationArr.push(element.id)
						// console.log(specificationArr,'规格');
					}
				})
			})
			that.getSkulist(specificationArr)
		},


		//加入购物车
		getAddCart() {
			const params = {
				cartType: 'CART',
				skuId: this.shopGoodsSkuDefault.id,
				num: this.num,
			}
			addCart(params).then(res => {
				if (res.code == 200) {
					this.$message({
						message: res.message,
						type: 'success'
					});
				} else {
					this.$message({
						message: res.msg,
						type: 'error'
					});
				}
				console.log(res, '加入购物车');
				this.$store.dispatch('getCarNum')

			})
			this.$forceUpdate()
		},

		//点击立即购买
		payNowClick() {
			// console.log(this.shopGoodsSkuDefault.number,this.num,'立即购买');
			if (this.num > this.shopGoodsSkuDefault.number) {
				this.$message({
					message: '库存不足',
					type: 'error'
				});
				return
			}
			const arr = []
			arr.push({ num: this.num, skuId: this.shopGoodsSkuDefault.id })
			this.$router.push({ path: '/account', query: { cartType: 'BUY_NOW', shopOerderAddDTOList: JSON.stringify(arr) } })
		},

		seeThis(index) {
			// 设置预览框显示状态为隐藏
			this.isShow = 0
			// 设置预览框图片地址
			this.url = this.imgUrl + this.shopDetails.galleryArr[index];
			// 设置预览框图片索引
			this.urlIndex = index;
		},
		move(e) {
			// console.log(e,'33');
			// 获取鼠标位置
			var x = e.layerX - 75;
			var y = e.layerY - 75;

			// 防止鼠标预览区域移动到原图区域左边以外
			if (x < 0) {
				x = 0;
			} else if (x > 300) {
				// 防止预览区域移动到右边以外
				x = 300;
			}

			if (y < 0) {
				y = 0;
			} else if (y > 300) {
				y = 300;
			}

			// 设置预览框位置
			this.cursorMask.transform = `translate(${x}px, ${y}px)`;
			// 设置预览框图片位置
			this.imgMove.transform = `translate(-${4 * x}px, -${4 * y}px)`;
			// console.log(this.cursorMask.transform);
			// console.log(this.imgMove.transform);
			// console.log(this.Bigurl,'sada');
		},
		seeBegin() {
			// 设置预览框图片地址
			this.Bigurl = this.url;
			console.log(this.Bigurl, 'sada');
			// 设置预览框显示状态为显示
			this.isShow = 1;
		},
		seeEnd() {
			// 设置预览框图片地址为空
			this.Bigurl = "";
			// 设置预览框显示状态为隐藏
			this.isShow = 0;
		},
		handleSelect() {

		},
		//切换评价和详情
		shopJP(index) {
			if (index == 1) {
				this.jieshaoPingjiaShow = true;
			} else {
				this.jieshaoPingjiaShow = false;
			}
		},

		click(e) {
			if (e == 1) {
				this.$router.push({
					path: '/shopIndex',
					query: { id: this.store.id }
				})
			} else {
				this.$router.push({
					path: '/shops',
					query: { id: this.store.id }
				})
			}
		}
	}
}
</script>
<style scoped lang="less">
@import 'index.less';

.t_img {
	width: 1280px;
	height: auto;
	margin: 0 auto;
	cursor: pointer;

	img {
		width: 100%;
		height: 140px;
	}
}

.nav {
	width: 100%;
	height: 50px;
	background: #404D6A;

	.nav_row {
		width: @container-width;
		height: auto;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: flex-start;
		line-height: 50px;
		text-align: center;

		.nr_arrange {
			width: 230px;
			height: 50px;
			background: #046eff;
			color: white;
			cursor: pointer;
		}

		.n_nav {
			padding: 0 40px;
			color: #fff;
			box-sizing: border-box;
			cursor: pointer;

			&:hover {
				color: white;
				background: #046eff;
			}
		}
	}
}

.el-menu-demo {
	background-color: @main-color-3;
}
</style>