<template>
  <div class="home">
    <!-- <NavTop :type='1'></NavTop> -->
    <ProductList :type='1' ref="list" :pdata="pdata" :shopGoodsList="shopGoodsList"></ProductList>
  </div>
</template>
  
<script>
import { shopGoodsList } from '@/api/shop/shop.js'
import config from '@/config/index.js'
import NavTop from '@/layouts/NavTop'
import ProductList from '@/components/productList'
export default {
  name: "home",
  data() {
    return {
      imgUrl: config.IMGURL,
      page: {
        pageNum: 1,
        pageSize: 10
      },
      
      shopGoodsList: [],
      search: '',
      sortBy: '',
      sortStatus: '',
      pdata:[]
    }
  },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val)
        if (val.query.search) {
          this.search = val.query.search
        } else if (val.query.nid) {
          this.cid = val.query.nid
        }
      },
      // 深度观察监听
      deep: true,
      immediate: true // 监听到路由的变化立即执行
    }
  },

  components: {
    NavTop,
    ProductList
  },

  mounted() {
    this.getShopGoodsList()
  },

  methods: {
    //获取产品列表
    getShopGoodsList() {
      const params = {
        ...this.page,
        keywords: this.search,
        cateIdContainChild: this.cid,
        sortBy: this.sortBy,
        sortStatus: this.sortStatus
      }
      shopGoodsList(params).then(res => {
        console.log('搜索结果列表',res.data.records)
        this.shopGoodsList = res.data.records
        this.pdata = res.data
        this.$refs.list.total = res.data.total
      })
    },

    //切换页码
    changePage(e) {
      this.page.pageNum = e;
      this.getShopGoodsList()
    },
  }

}
</script>
  
<style scoped lang="less">
.home {
  width: 100%;
  height: auto;
  overflow: hidden;


  .product {
    width: 1280px;
    margin: 0 auto;
    overflow: hidden;

    .p_title {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      margin-top: 50px;
      margin-bottom: 4px;

      .p_t_left {
        width: 27px;
        height: 72px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .p_t_right {
        display: flex;
        flex-direction: column;
        margin-left: 12px;
        text-align: left;

        div {
          &:nth-child(1) {
            font-size: 30px;
            font-weight: bold;
          }

          &:nth-child(2) {
            font-size: @main-fontsize-4;
            color: #C0C0C0;
            line-height: 35px;
          }
        }

      }
    }

    .product-box {
      padding: 0px 0 0px;
      overflow: hidden;

      .list-box {
        width: 100%;

        .list {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          width: 100%;
          margin-bottom: 14px;

          .item {
            background: white;
            padding: 25px 15px;
            box-sizing: border-box;
            box-shadow: 5px 5px 10px 5px #F9F9F9;
            margin: 0 10px 20px 10px;
            flex: 1;
            width: calc((100% - 20px*5) / 5);

            min-width: calc((100% - 20px*5) / 5);
            max-width: calc((100% - 20px*5) / 5);

            &:nth-child(5n) {
              /* 去除第3n个的margin-right */
              margin-right: 0;
            }

            .item-img {
              width: 195px;
              height: 195px;
              margin: 0 auto;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .item-info {
              display: flex;
              flex-direction: column;
              justify-content: flex-start;
              width: 195px;

              .i_i_title {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin: 13px 0;

                .price {
                  color: @main-color-2;
                  font-size: @main-fontsize-1;
                  font-weight: bold;
                  cursor: pointer;

                  span {
                    font-size: 12px;
                    line-height: 35px;
                    margin-right: -5px;
                  }
                }

                .p_right {
                  color: @main-color-4;
                  font-size: @main-fontsize-3;
                  line-height: 35px;
                }
              }

              .p_name {
                width: 100%;
                color: @main-color-4;
                font-size: @main-fontsize-2;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                /* 显示多行文本容器 */
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                cursor: pointer;
                margin-bottom: 8px;
              }

              .info_name {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;

                img {
                  width: 15px;
                  height: auto;
                  vertical-align: middle;
                  margin-right: 5px;
                }

                color: @main-color-4;
                font-size:@main-fontsize-3;
              }
            }
          }
        }
      }
    }
  }
}
</style>
  