<template>
	<div>
		<table class="orderInfo-container">
			<thead>
				<tr>
					<th>订单信息</th>
					<th></th>
				</tr>
			</thead>
			<tbody>
				<tr class="tr-1">
					<td class="td-1">收货人：{{ orderInfo.consignee}}</td>
					<td class="td-2">配送方式：{{ orderInfo.deliveryTypeDesc}}</td>
				</tr>
				<tr class="tr-2">
					<td class="td-1">收货地址：{{ orderInfo.address }}</td>
					<td class="td-2">配送时间：{{ orderInfo.deliveryTime }}</td>
				</tr>
				<tr class="tr-3">
					<td class="td-1">手机号码：{{ orderInfo.phone }}&nbsp;&nbsp;&nbsp;支付金额：￥{{ orderInfo.actualPrice }}</td>
					<td class="td-2">配送司机：{{ orderInfo.deliveryDriver }}</td>
				</tr>
				<tr class="tr-4">
					<td class="td-1">下单时间：{{ orderInfo.createdTime }}</td>
					<td class="td-2">手机号码：{{ orderInfo.deliveryPhone }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
export default {
	props: {
		orderInfo: {
			type: Object,
			default: {},
		},
	},
}
</script>

<style lang="less" scoped>
@import url('./index.less');
</style>