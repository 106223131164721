<template>
	<div>
		<el-dialog :title="title" :visible.sync="dialogVisible" width="681px" :before-close="handleClose">
			<!-- <span>这是一段信息</span> -->
			<el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="收货人" label-width="150px" prop="name" :rules="[{ required: true, message: '收货人不能为空' }]">
					<el-input v-model.number="numberValidateForm.name" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item
					label="联系电话"
					label-width="150px"
					prop="mobile"
					:rules="[
						{ required: true, message: '联系电话不能为空' }
					]"
				>
					<el-input v-model.number="numberValidateForm.mobile" autocomplete="off"></el-input>
				</el-form-item>

				<!-- :rules="[
						{ required: true, message: '所在地址不能为空' },
					]"> -->
				<el-form-item label="所在地址" label-width="150px" prop="districtId" :rules="[
						{ required: true, message: '所在地址不能为空' },
					]"
				>
					<Addressthree ref="citySelectUpdate" :firstApi="getProvinces" :secondApi="getCitys" :thirdApi="getDistricts" :fourthApi="getStreets" :initData="cityInitDataUpdate" @success-select="successSelectUpdate"> </Addressthree>
				</el-form-item>

				<el-form-item label="详细地址" label-width="150px" prop="address" :rules="[{ required: true, message: '详细地址不能为空' }]">
					<el-input type="textarea" placeholder="请输入内容" v-model="numberValidateForm.address"> </el-input>
				</el-form-item>
				<el-form-item label="设为默认地址" label-width="150px" prop="isDefault">
					<el-switch
							v-model="numberValidateForm.isDefault"
							active-color="#2F7BFF"
							>
					</el-switch>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="danger" @click="submitDialog('numberValidateForm')">{{title=='新增地址'?'确认新增':'保存修改'}}</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { getProvinces, getCitys, getDistricts, getStreets } from '@/api/common/address.js' //省市区的接口
import {addAddress,updateAddress} from '@/api/user/index.js' //新增地址接口
import Addressthree from '@/components/Addressthreelevel'
export default {
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
		title:{
			type:String,
			default:''
		},
		addressDetails: {
			type: Object,
			default: () => {
				return {}
			}
		}
	},
	components: {
		Addressthree,
	},
	data() {
		return {
			getProvinces: getProvinces,
			getCitys: getCitys,
			getDistricts: getDistricts,
			getStreets: getStreets,

			cityInitDataUpdate: [],
			cityInitData: [
				{id: '1401797451593024144', name: '山东省'},
				{id: '1401797451597218145', name: '临沂市'},
				{id: '1401797451597218191', name: '兰山区'},
				{id: "1401797451597218201", name: '兰山街道'}
			],

			numberValidateForm: {
				name: '',
				mobile: '',
				// addressSanji: '',

				provinceId:'',//省id
				cityId:'',//城市ID
				countyId:'',//区县id
				districtId:'1401797451597218201',//街道id

				address: '',
				isDefault:false,
			},
		}
	},
	watch: {
		addressDetails: {
			handler(newVal, oldVal) {
				console.log('addressDetails', newVal)
				this.numberValidateForm = newVal
				this.numberValidateForm.isDefault = this.numberValidateForm.isDefault== 1?true:false;
			}
		}
	},
	created() {
		this.cityInitDataUpdate = this.mDeepCopy(this.cityInitData)
		// console.log('this.cityInitDataUpdate', this.cityInitDataUpdate)
	},
	methods: {
		init(){
			this.cityInitData= [
				{id: '1401797451593024144', name: '山东省'},
				{id: '1401797451597218145', name: '临沂市'},
				{id: '1401797451597218191', name: '兰山区'},
				{id: "1401797451597218201", name: '兰山街道'}
			],
			this.numberValidateForm.name = ''
			this.numberValidateForm.mobile = ''
			this.numberValidateForm.address = ''
		},
		handleClose() {
			this.$emit('closeDialog', false)
		},
		successSelectUpdate(data) {
			console.log('ffffff', data)
			this.numberValidateForm.provinceId = data[0].id
			this.numberValidateForm.cityId = data[1].id
			this.numberValidateForm.countyId = data[2].id
			this.numberValidateForm.districtId = data[3].id
		},
		submitDialog(formName) {
			let that = this;
			that.numberValidateForm.isDefault = that.numberValidateForm.isDefault == true? 1 : 0;
			const params = {
				...this.numberValidateForm
			}
			// console.log('params', params)
			this.$refs[formName].validate((valid) => {
				if (valid) {
					console.log('submit!',that.title)
					if(that.title=='新增地址'){
						addAddress(params).then(res=> {
							// console.log('新增', res)
							if(res.code == 200){
								this.$message({
									showClose: true,
									message: res.message,
									type: 'success'
								});
								this.$emit('submitDialog')
								that.init()
							}
						})
					}else{
						that.updateAddress(params)
					}
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},

		//修改地址
		updateAddress(params){
			updateAddress(params).then(res => {
				// console.log('修改', res)
				if(res.code == 200){
					this.$message({
						showClose: true,
						message: res.message,
						type: 'success'
					});
					this.$emit('submitDialog')
				}
			})
		},

		mDeepCopy(target) {
			let result
			if (typeof target === 'object') {
				if (Array.isArray(target)) {
					result = []
					for (let i in target) {
						result.push(this.mDeepCopy(target[i]))
					}
				} else if (target === null) {
					result = null
				} else if (target.constructor === RegExp) {
					result = target
				} else {
					result = {}
					for (let i in target) {
						result[i] = this.mDeepCopy(target[i])
					}
				}
			} else {
				result = target
			}
			return result
		},
	},
}
</script>
<style scoped lang="less">
/deep/.el-form-item__label {
	color: #999999;
	padding: 0 57px 0 0;
}
/deep/.el-dialog__body {
	padding: 0 50px 0 30px;
	margin: 43px 0 0 0;
}
/deep/.el-dialog__footer {
	// border: 1px saddlebrown solid;
	// width: 100%;
	display: flex;
	justify-content: center;
}
.el-button {
	background-color: #f23030;
	border: none;
	border-radius: 0;
	width: 172px;
	height: 51px;
	margin: 20px 0 41px 0;
}
.el-button:hover {
	background-color: #f23030;
}
</style>