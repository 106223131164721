<template>
	<div class="user-container container">
		<user-info @updateUserInfo="updateUserInfo" :userInfo="userInfo" :imgUrl="imgUrl" :orderInfo="orderInfo" @clickOrderItem="clickOrderItem"></user-info>
		<Address @updateAddress="updateAddress" @deleteAddress="deleteAddress" @setDefaultAddress="setDefaultAddress" :addressList="addressList" :total="total"
				@addressFenye="addressFenye"></Address>
		
		<!-- 新增地址和修改地址组件弹窗 -->
		<UpdateAddress :dialogVisible="updateAddressShow" @closeDialog="closeDialog" @submitDialog="submitDialog" :title="title" :addressDetails="addressDetails">
		</UpdateAddress>


		<!-- 修改用户信息组件弹窗 -->
		<UpdateUserInfo :userInfo="userInfo" :dialogVisible="updateUserInfoShow" @closeDialogUserInfo="closeDialogUserInfo" 
				@submitDialogUserInfo="submitDialogUserInfo" v-if="userInfo"></UpdateUserInfo>
	</div>
</template>
<script>
import UpdateAddress from '../../components/updateAddress.vue'
import UpdateUserInfo from './components/UpdateUserInfo'
import UserInfo from './components/UserInfo'
import Address from './components/Address'

import {memberInfo,selfOrderTitle,addresslist,addRessDetails,deleteAddress,setDefaultAddress} from '@/api/user/index.js'
import config from '@/config/index.js'

export default {
	components: {
		UserInfo,
		Address,
		UpdateAddress,
		UpdateUserInfo
	},
	data() {
		return {
			imgUrl: process.env.VUE_APP_BASE_URL,//config.IMGURL,
			userInfo: {},
			orderInfo: [],
			title:'',//新增地址和修改地址组件弹窗标题
			updateAddressShow:false,//打开修改地址的弹窗
			updateUserInfoShow:false,
			page:{
				pageNum:1,
				pageSize:10
			},
			addressList:[],
			total:100,

			addressDetails:null,//修改收货地址时获取的收货地址详情
		}
	},
	mounted(){
		//获取用户信息
		this.getMemberInfo()
		//获取订单数量
		this.getSelfOrderTitle()
		//收货地址
		this.getAddresslist()
	},
	methods: {
		//获取会员信息
		getMemberInfo(){
			memberInfo().then(res=>{
				if(res.code == 200){
					this.userInfo = res.data
					// console.log(this.userInfo,'用户信息')
				}
			})
		},
		//个人中心获取订单数量
		getSelfOrderTitle(){
			selfOrderTitle().then(res=>{
				if(res.code == 200){
					// console.log(res.data,'sss')
					this.orderInfo = res.data
				}
			})
		},
		//个人中心查询收货地址
		getAddresslist(){
			const params = {
				...this.page
			}
			addresslist(params).then(res=>{
				if(res.code == 200){
					console.log(res.data,'地址')
					this.addressList = res.data.records
					this.total = res.data.total
				}
			})
		},
		addressFenye(e){
			console.log(e,'e')
			this.page.pageNum = e
			this.getAddresslist()
		},
		//获取收货地址详细信息
		getAddRessDetails(id){
			addRessDetails(id).then(res => {
				if(res.code == 200){
					this.addressDetails = res.data
				}
				// console.log(res,'收货地址x')
			})
		},
		//删除地址
		deleteAddress(id){
			let that = this;
			const ids = []
			ids.push(id)
			// const params = {
			// 	ids:ids
			// }
			 this.$confirm('此操作将删除该地址, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				deleteAddress(ids).then(res => {
					if(res.code == 200){
						this.$message({
							showClose: true,
							message: res.message,
							type: 'success'
						});
						that.getAddresslist()
					}
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '已取消删除'
				});          
			});
			
		},
		//设置默认地址
		setDefaultAddress(id){
			let that = this;
			const params = {
				id:id
			}
			setDefaultAddress(params).then( res => {
				if(res.code == 200){
					// console.log(res.data,'设置默认地址')
					this.$message({
						showClose: true,
						message: res.message,
						type: 'success'
					});
					that.getAddresslist()
				}
			})
		},


		// 修改用户信息
		updateUserInfo() {
			this.updateUserInfoShow = true
		},
		submitDialogUserInfo(){
			this.updateUserInfoShow = false
			this.getMemberInfo()
		},
		closeDialogUserInfo(){
			this.updateUserInfoShow = false
			this.getMemberInfo()
		},


		//打开修改地址信息的弹窗
		updateAddress(data) {
			if(data == 'add'){
				this.title = '新增地址'
			}else{
				this.getAddRessDetails(data)
				this.title = '修改地址'
			}
			this.updateAddressShow = true
		},
		submitDialog(){
			this.updateAddressShow = false
			this.getAddresslist()
		},
		closeDialog(){
			this.updateAddressShow = false
		},


		//接受点击订单数量传过来的tab值
		clickOrderItem(data){
			console.log(data,'点击了订单数量')
			this.$router.push({path:'/orderlist',query: { tabIndex:data}})
		}
	}
}
</script>
<style lang="less" scoped>
@import 'index.less';
@import '@/assets/theme/index.less';

.container {
	width: @container-width;
	margin: 0 auto;
	padding-top: 30px;
	box-sizing: border-box;
}
</style>