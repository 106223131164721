import instance from '../_http'

// 登录
export function login(data) {
    return instance.request({
        url: `/passport/member/apiLogin`,
        method: 'post',
        data: data
    })
}
// 登录
export function getCode() {
    return instance.request({
        url: `/captchaImage`,
        method: 'get',
    })
}

//获取当前会员详细信息
export function memberInfo() {
    return instance.request({
        url: `/dxjc/api/member`,
        method: 'get',
    })
}

//修改会员信息
export function updatememberInfo(data) {
    return instance.request({
        url: `/dxjc/api/member`,
        method: 'put',
        data
    })
}

// 个人中心订单数量获取接口
export function selfOrderTitle() {
    return instance.request({
        url: `/dxjc/api/order/selfOrderTitle`,
        method: 'get',
    })
}

//个人中心查询收货地址
export function addresslist(params) {
    return instance.request({
        url: `/dxjc/shopAddressApi/list`,
        method: 'get',
        params: params
    })
}

//新增收货地址
export function addAddress(data) {
    return instance.request({
        url: `/dxjc/shopAddressApi/shopAddressApiSave`,
        method: 'post',
        data: data
    })
}
//修改收货地址
export function updateAddress(data) {
    return instance.request({
        url: `/dxjc/shopAddressApi`,
        method: 'put',
        data
    })
}
//获取收货地址详细信息
export function addRessDetails(id) {
    return instance.request({
        url: `/dxjc/shopAddressApi/${id}`,
        method: 'get',
    })
}
//删除收货地址
export function deleteAddress(params) {
    return instance.request({
        url: `/dxjc/shopAddressApi/` + params,
        method: 'delete',
    })
}
//设置默认收货地址
export function setDefaultAddress(params) {
    return instance.request({
        url: `/dxjc/shopAddressApi/setDefaultAdd`,
        method: 'get',
        params
    })
}

//退出登录
export function logout() {
    return instance.request({
        url: `/dxjc/api/logout`,
        method: 'post',
    })
}

//商户注册
export function register(data) {
    return instance.request({
        url: `/dxjc/api/stores/addNewShopStores`,
        method: 'post',
        data
    })
}

//
export function store_size(data) {
    return instance.request({
        url: `/dxjc/api/dict/data/type/store_size`,
        method: 'get',
    })
}
//
export function store_type(data) {
    return instance.request({
        url: `/dxjc/api/dict/data/type/store_type`,
        method: 'get',
    })
}
//商铺关注
export function addShopFollow(data) {
    return instance.request({
        url: `/dxjc/api/follow/addShopFollow`,
        method: 'post',
        data
    })
}
//商铺取消关注
export function deleteFollow(data) {
    return instance.request({
        url: `/dxjc/api/follow/${data}`,
        method: 'delete',
    })
}
//查询关注列表
export function followList(data) {
    return instance.request({
        url: `/dxjc/api/follow/list`,
        method: 'get',
    })
}





