<template>
	<div class="userinfo-container">
		<div class="header">
			<img :src="imgUrl+userInfo.avatar" alt="" />
			<div class="header-info">
				<span class="header-info-name">{{userInfo.nickName}}</span>
				<p class="header-info-content"><span>账号:{{userInfo.userName}}</span><span>联系电话：{{userInfo.phone}}</span></p>
				<p class="header-info-content"><span> 联系地址：
					{{userInfo.provinceName+userInfo.cityName+userInfo.countyName}}
					{{userInfo.districtName==null?'':userInfo.districtName}}
					{{userInfo.address}}
				</span></p>
			</div>
			<div class="header-btn">
				<button class="header-btn-info" @click="updateUserInfo">修改信息</button>
				<button class="header-btn-focus" @click="openVn">关注店铺</button>
			</div>
		</div>
		<div class="tab">
			<div class="tab-list">
				<div class="tab-list-item" v-for="(item,index) in orderInfo" :key="index" @click="clickOrderItem(item.index)">
					<span>{{item.lable}} <span>{{item.number}}</span></span>
				</div>
				<!-- <div class="tab-list-item">
					<span>待收货 <span>{{orderInfo[1].number}}</span></span>
				</div>
				<div class="tab-list-item">
					<span>待评价 <span>{{orderInfo[2].number}}</span></span>
				</div>
				<div class="tab-list-item">
					<span>售后/退货<span>{{orderInfo[3].number}}</span></span>
				</div> -->
			</div>
		</div>
	</div>
</template>
<script>

export default {
	name: 'UserInfo',
	props: {
		userInfo: {
			type: Object,
			default: () => {
				return {};
			}
		},
		imgUrl: {
			type: String,
			default: () => {
				return '';
			}
		},
		orderInfo:{
			type: Array,
			default: () => {
				return [];
			}
		}
	},
	watch: {
		orderInfo(val) {
			this.orderInfo = val;
			// console.log(this.orderInfo,'子组件');
		}
	},
	data() {
		return {
			
		};
	},
	methods: {
		updateUserInfo() {
			this.$emit('updateUserInfo');
		},
		clickOrderItem(index){
			this.$emit('clickOrderItem',index);
		},
		openVn() {
			// const h = this.$createElement;
            // this.$message({
            //     message: h('p', null, [
            //         h('span', null, '正在开发中~ '),
            //     ])
            // });
            this.$router.push('/merchant')
        },
	},
}
</script>
<style lang="less" scoped>
@import 'index.less';
</style>