<template>
	<div>
		<el-dialog title="修改信息" :visible.sync="dialogVisible" width="681px" :before-close="handleClose">
			<!-- <span>这是一段信息</span> -->
			<el-form :model="numberValidateForm" ref="numberValidateForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="头像" label-width="150px" prop="touxiang" :rules="[{ required: true, message: '头像不能为空' }]">
					<imageUpload v-model="numberValidateForm.touxiang" :limit="1"/>
				</el-form-item>
				<el-form-item label="机构名称" label-width="150px" prop="buyOrgName" :rules="[{ required: true, message: '机构名称不能为空' }]">
					<el-input v-model="numberValidateForm.buyOrgName" autocomplete="off"></el-input>
				</el-form-item>
				<el-form-item
					label="联系电话"
					label-width="150px"
					prop="phone"
					:rules="[
						{ required: true, message: '联系电话不能为空' },
					]"
				>
					<el-input v-model.number="numberValidateForm.phone" autocomplete="off"></el-input>
				</el-form-item>

				<!-- :rules="[
						{ required: true, message: '所在地址不能为空' },
					]"> -->
				<el-form-item label="联系地址" label-width="150px" prop="districtId">
					<Addressthree ref="citySelectUpdate" :firstApi="getProvinces" :secondApi="getCitys" :thirdApi="getDistricts" :fourthApi="getStreets" :initData="cityInitDataUpdate" @success-select="successSelectUpdate"> </Addressthree>
				</el-form-item>

				<el-form-item label="详细地址" label-width="150px" prop="address" :rules="[{ required: true, message: '详细地址不能为空' }]">
					<el-input type="textarea" placeholder="请输入内容" v-model="numberValidateForm.address"> </el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="danger" @click="submitDialog('numberValidateForm')">保存修改</el-button>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { getProvinces, getCitys, getDistricts, getStreets } from '@/api/common/address.js' //省市区的接口
import {updatememberInfo} from '@/api/user/index.js'
import Addressthree from '@/components/Addressthreelevel'
import config from '@/config/index.js'
export default {
	props: {
		dialogVisible: {
			type: Boolean,
			default: false,
		},
		userInfo: {
			type: Object,
			default: () => {
				return {};
			}
		},
	},
	components: {
		Addressthree,
	},
	watch: {
		// dialogVisible(newVal, oldVal) {
		// 	this.dialogVisible = newVal;
		// },
		userInfo(newVal, oldVal) {
			console.log(newVal,'用户信ss息');
			this.numberValidateForm = newVal;
			this.cityInitData = [
				{id: newVal.provinceId, name: newVal.provinceName},
				{id: newVal.cityId, name: newVal.cityName},
				{id: newVal.countyId, name: newVal.countyName},
				{id: newVal.districtId, name: newVal.districtName}
			]
			// console.log(this.cityInitData,'cityInitData');
			this.cityInitDataUpdate = this.mDeepCopy(this.cityInitData)
			// console.log('this.cityInitDataUpdate', this.cityInitDataUpdate)
			this.numberValidateForm.touxiang =  this.numberValidateForm.avatar 
			console.log('this.imgUrltouxiang', this.numberValidateForm.touxiang)
		}
	},
	data() {
		return {
			imgUrl:config.IMGURL,
			getProvinces: getProvinces,
			getCitys: getCitys,
			getDistricts: getDistricts,
			getStreets: getStreets,

			cityInitDataUpdate: [],
			cityInitData: [
				// {id: '1401797451593024144', name: '山东省'},
				// {id: '1401797451597218145', name: '临沂市'},
				// {id: '1401797451597218191', name: '兰山区'},
				// {id: '', name: ''}
			],

			numberValidateForm: {
                name: '',
				phone: '',
				touxiang:'',
				// addressSanji: '',
				buyOrgName:'',//机构

				provinceId:'',//省id
				cityId:'',//城市ID
				countyId:'',//区县id
				districtId:'1401797451597218201',//街道id

				address: '',
				isDefault:false,
			},
		}
	},
	created() {
		// console.log('this.imgUrl', this.imgUrl)
		// this.cityInitDataUpdate = this.mDeepCopy(this.cityInitData)
		// console.log('this.cityInitDataUpdate', this.cityInitDataUpdate)
	},
	methods: {
		handleClose() {
			this.$emit('closeDialogUserInfo', false)
		},
		successSelectUpdate(data) {
			console.log('ffffff', data)
			this.numberValidateForm.provinceId = data[0].id
			this.numberValidateForm.cityId = data[1].id
			this.numberValidateForm.countyId = data[2].id
			this.numberValidateForm.districtId = data[3].id
		},
		submitDialog(formName) {
			this.numberValidateForm.avatar = this.numberValidateForm.touxiang
			console.log(this.numberValidateForm,'ff')
			this.$refs[formName].validate((valid) => {
				if (valid) {
					const params = {
						...this.numberValidateForm
					}
					updatememberInfo(params).then(res => {
						console.log('res', res)
						if(res.code==200){
							this.$message({
								message: '修改成功',
								type: 'success'
							})
							this.$emit('submitDialogUserInfo')
						}
					})
					
				} else {
					console.log('error submit!!')
					return false
				}
			})
		},

		mDeepCopy(target) {
			let result
			if (typeof target === 'object') {
				if (Array.isArray(target)) {
					result = []
					for (let i in target) {
						result.push(this.mDeepCopy(target[i]))
					}
				} else if (target === null) {
					result = null
				} else if (target.constructor === RegExp) {
					result = target
				} else {
					result = {}
					for (let i in target) {
						result[i] = this.mDeepCopy(target[i])
					}
				}
			} else {
				result = target
			}
			return result
		},
	},
}
</script>
<style scoped lang="less">
/deep/.el-upload--picture-card{
	width: 97px;
	height: 97px;
	border-radius: 50%;
	line-height: 105px;
}
/deep/.el-form-item__label {
	color: #999999;
	padding: 0 57px 0 0;
}
/deep/.el-dialog{
	border-radius: 8px;
}
/deep/.el-dialog__body {
	padding: 0 50px 0 30px;
	margin: 43px 0 0 0;
}
/deep/.el-dialog__footer {
	// border: 1px saddlebrown solid;
	// width: 100%;
	display: flex;
	justify-content: center;
}
.el-button {
	background-color: #f23030;
	border: none;
	border-radius: 0;
	width: 172px;
	height: 51px;
	margin: 20px 0 41px 0;
}
.el-button:hover {
	background-color: #f23030;
}
/deep/.el-upload-list--picture-card .el-upload-list__item{
	border-radius: 50%;
	height: 90px;
	width: 90px;
}
</style>