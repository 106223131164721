<template>
  <div class="home">
    <NavTop :type='0'></NavTop>
    <div class="swiper">
      <div class="swiper_content">
        <el-carousel height="538px">
          <el-carousel-item v-for="item in swiperData" :key="item.id">
            <img class="img" :src="imgUrl + item.imgUrl" :onerror="defaultImg" @click="click2(item.linkUrl)">
          </el-carousel-item>
        </el-carousel>
        <div class="login_user" v-if="!userInfo">
          <div class="user_img">
            <img src="@/assets/images/user.png" alt="">
          </div>
          <div class="u_title">Hi~欢迎来到智采云~</div>
          <div class="user_button">
            <div class="ub_left">
              <el-menu :default-active="activeIndex" class="el-menu-demo" mode="horizontal" @select="handleSelect">
                <el-submenu>
                  <template slot="title">用户登录</template>
                  <el-menu-item index="2-1" @click="click(0)">采购端登录</el-menu-item>
                  <el-menu-item index="2-2" @click="click(5)">商户端登录</el-menu-item>
                </el-submenu>
              </el-menu>
            </div>
            <div class="ub_right" @click="click(4)">商家入驻</div>
          </div>
          <div class="user_bottom">
            <div class="ub_left" @click="click(1)">
              <img src="@/assets/images/icon1.png" alt="">
              <div>购物车</div>
            </div>
            <div class="ub_right" @click="click(2)">
              <img src="@/assets/images/icon2.png" alt="">
              <div>我的订单</div>
            </div>
          </div>
        </div>

        <div class="login_user2 login_user" v-if="userInfo">
          <div class="user_img">
            <el-image style="width: 65px; height: 65px" :src="imgUrl + userInfo.avatar" fit="cover"></el-image>
          </div>
          <div class="u_title2 u_title">{{ userInfo.nickName }}</div>
          <div class="user_button">
            <div class="ub_left2" @click="click(3)">个人中心</div>
          </div>
          <div class="user_bottom">
            <div class="ub_left" @click="click(1)">
              <img src="@/assets/images/icon1.png" alt="">
              <div>购物车</div>
            </div>
            <div class="ub_right" @click="click(2)">
              <img src="@/assets/images/icon2.png" alt="">
              <div>我的订单</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ProductList :type='0' :shopGoodsList="shopGoodsList" :pdata="pdata"></ProductList>
    <ProductList :type='3' :shopGoodsList="archGoodsList" :pdata="pdata"></ProductList>
    <div class="shops">
      <div class="s_title">精选商铺</div>
      <div class='s_content'>
        <div class="srow" v-for="(item, index) in shopData" :key="index" @click="storeDetail(item.linkId)">
          <img :src="imgUrl + item.imgUrl" :onerror="defaultImg3">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTopStore, getSwiper, listAllHotGoods, getListArchGoods } from '@/api/shop/shop.js'
import config from '@/config/index.js'
import NavTop from '@/layouts/NavTop'
import ProductList from '@/components/productList'
export default {
  name: "home",
  components: {
    NavTop,
    ProductList,
  },
  data() {
    return {
      imgUrl: config.IMGURL,
      showLogin: 0,
      value1: '',
      userInfo: {},
      swiperData: null,
      shopData: null,
      shopGoodsList: [],
      archGoodsList: [],
      MerchantUrl: process.env.VUE_APP_MERCHANT_URl,
      pdata: [],
      page: {
        pageNum: 1,
        pageSize: 10
      },
      defaultImg: 'this.src = "' + require('../assets/images/err3.png') + '"', //默认轮播图片的地址
      defaultImg3: 'this.src = "' + require('../assets/images/err2.png') + '"', //默认轮播图片的地址
    }
  },
  mounted() {
    this.getShopGoodsList()
    this.getTopStore()
    this.getSwiper()
    this.getListArchGoods()
    this.userInfo = this.$storage.getItem('userinfo')
  },
  methods: {
    handleChange(e) {
      console.log(e);
    },
    //获取广告位
    getSwiper() {
      getSwiper('home').then(res => {
        this.swiperData = res.data
      })
    },

    //获取商铺
    getTopStore() {
      getTopStore().then(res => {
        console.log(res, 'res2')
        this.shopData = res.data
      })
    },
    //获取产品列表
    getShopGoodsList() {
      const params = {
        ...this.page,
      }
      listAllHotGoods(params).then(res => {
        console.log('热门商品列表', res.data)
        this.shopGoodsList = res.data
      })
    },

    getListArchGoods() {
      // const params = {
      //   pageNum: 1,
      //   pageSize: 100
      // }
      getListArchGoods().then(res => {
        console.log('热门商品列表', res.data)
        this.archGoodsList = res.data
      })
    },


    //店铺详情
    storeDetail(e) {
      this.$router.push({
        path: '/shops',
        query: {
          id: e
        }
      })
    },

    click2(e) {
      window.open(e, '_blank')
    },

    //点击
    click(e) {
      switch (e) {
        case 0:
          this.$router.push('/login')
          break;
        case 1:
          this.$router.push('/shopcar')
          break;
        case 2:
          this.$router.push('/orderlist')
          break;
        case 3:
          this.$router.push('/user')
          break;
        case 4:
          this.$router.push('/register')
          break;
        case 5:
          // window.location.href = this.MerchantUrl
           window.open(this.MerchantUrl)
          break;
      }
    }
  }
}
</script>

<style scoped lang="less">
/deep/.el-submenu__title {
  width: 100% !important;
  height: 34px !important;
  line-height: 34px !important;
  background: none !important;
  border-radius: 4px;
  text-align: center !important;
  padding: 0 0 !important;
  color: #016BFF !important;
}

/deep/.el-submenu {
  width: 100% !important;
}

.home {
  width: 100%;
  height: auto;
  overflow: hidden;

  .swiper {
    width: 100%;
    height: 538px;
    margin: 0 auto;
    position: relative;

    .swiper_content {
      width: 100%;
      height: 538px;
      margin: 0 auto;
      position: relative;

      .login_user2 {
        background: #5F738F !important
      }

      .login_user {
        width: 281px;
        overflow: hidden;
        position: absolute;
        top: 64px;
        right: 16.5%;
        background: rgba(255, 255, 255, 0.825);
        border-radius: 5px;
        z-index: 100;

        .user_img {
          width: 65px;
          height: 65px;
          margin: 55px auto 17px;
          cursor: pointer;
          border-radius: 50px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .u_title {
          width: 100%;
          text-align: center;
          color: @main-color-4;
          font-size: @main-fontsize-2;
        }

        .u_title2 {
          color: white !important;
        }

        .user_button {
          width: 90%;
          margin: 24px auto 45px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-around;

          .ub_left2 {
            width: 104px;
            height: 35px;
            line-height: 35px;
            font-size: @main-fontsize-2;
            text-align: center;
            cursor: pointer;
            border-radius: 5px;
            color: white !important;
            background: #016BFF !important;
          }

          div {
            width: 104px;
            height: 35px;
            line-height: 35px;
            font-size: @main-fontsize-2;
            text-align: center;
            cursor: pointer;

            &:nth-child(1) {
              border: 1px solid #016BFF;
              border-radius: 5px;
              color: #016BFF;
              background: white;
              overflow: hidden;
            }

            &:nth-child(2) {
              border-radius: 5px;
              color: white;
              background: #016BFF;
            }
          }

        }

        .user_bottom {
          width: 100%;
          height: 91px;
          background: white;
          display: flex;
          flex-direction: row;
          justify-content: space-around;


          .ub_left {
            margin-top: 15px;
            cursor: pointer;
            text-align: center;
            overflow: hidden;

            img {
              width: 33px;
              height: 29px;
            }

            div {
              font-size: @main-fontsize-3 ;
              color: @main-color-4;
            }
          }

          .ub_right {
            margin-top: 15px;
            cursor: pointer;
            text-align: center;

            img {
              width: 29px;
              height: 24px;
            }

            div {
              font-size: @main-fontsize-3 ;
              color: @main-color-4;
            }
          }
        }
      }
    }

    .img {
      width: 100%;
      height: 538px;
      display: block;
      margin: 0 auto;
      cursor: pointer;
    }
  }

  .shops {
    width: 1280px;
    height: auto;
    margin: 0 auto 30px;
    overflow: hidden;

    .s_title {
      font-size: 30px;
      line-height: 45px;
      color: #363636;
      font-weight: bold;
      margin-bottom: 24px;
    }

    .s_content {
      display: flex;
      flex-direction: row;
      align-items: center;
      background: #F9F9F9;
      padding: 20px 0px 20px;
      box-sizing: border-box;

      .srow {
        flex: 1;
        margin: 0 10px 0px 10px;
        width: calc((100% - 20px*7) / 7);
        min-width: calc((100% - 20px*7) / 7);
        max-width: calc((100% - 20px*7) / 7);
        border-right: 1px dashed #ECECEC;
        cursor: pointer;

        img {
          width: 127px;
          height: 76px;
          display: block;
          margin: 0 auto;
        }

        &:nth-child(7n) {
          /* 去除第3n个的margin-right */
          margin-right: 0;
          border-right: none !important;
        }
      }
    }
  }
}
</style>
